import { RootStore } from '..';
import ProductClient from '../../api/ProductClient';
import {
    StoreEntity,
    loadingProductsStarted,
    productsReceived,
    slugProductLoading,
    slugProductNotFound,
    slugProductReceived,
} from '../reducer/product';
import { NeededPriceType } from '../../api/types/PriceType';
import { DetailedProduct } from '../../api/types/ClientProductDetailed';

/**
 * Request product-data from the api and store it in redux
 */
export default class ProductLoader {
    private client: ProductClient;
    private store: RootStore;

    constructor(client: ProductClient, store: RootStore) {
        this.client = client;
        this.store = store;
    }

    async loadProducts(ids: string[]): Promise<void> {
        const { country, currency, locale } = this.store.getState().context;
        const loadedProducts = this.store.getState().product;
        const idsToLoad = ids.filter(Boolean).filter((id) => {
            const data: StoreEntity | undefined = loadedProducts.products[id];
            if (!data?.product) {
                return true;
            }

            // load products of specific country or locale
            const { country, locale } = this.store.getState().context;
            const countryProducts = data.product?.variants?.filter(
                (variant) => variant.country === country && variant.locale === locale
            );
            if (countryProducts === undefined || countryProducts.length === 0) {
                return true;
            }

            // Retry failed requests
            return data.status === 'error';
        });

        if (idsToLoad.length === 0) {
            return;
        }

        this.store.dispatch(loadingProductsStarted({ ids: idsToLoad }));

        const products = await this.client.queryProducts(idsToLoad, country, currency, locale);

        this.store.dispatch(productsReceived({ products }));
    }

    async loadProductBySlug(slug: string, neededPriceType?: NeededPriceType, relatedSku?: string): Promise<void> {
        const { slugProduct } = this.store.getState().product;

        let product: DetailedProduct | undefined;

        // Use existing slug product (to not re-fetch data that is already provided by SSR)
        if (slugProduct?.slug === slug && slugProduct?.requestedPriceType === neededPriceType) {
            product = slugProduct.product;
        }

        if (!product) {
            this.store.dispatch(slugProductLoading({ slug }));
            product = await this.client.getProductBySlug(slug, neededPriceType, relatedSku);

            if (product) {
                this.store.dispatch(slugProductReceived({ product, slug }));
            } else {
                this.store.dispatch(slugProductNotFound());
            }
        }
    }
}
