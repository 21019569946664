import { useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
import {
    componentName as contentNavigationComponentName,
    CONTENT_NAVIGATION_HEIGHT,
} from '@mediashop/catalog-base/pattern/molecule/ContentNavigation/ContentNavigation';
import { componentName as accordionItemComponentName } from '@mediashop/catalog-base/pattern/molecule/AccordionPDP/AccordionItem/AccordionItem';
import { removeLocaleFromPathname } from '../helper/localeHelper';
import { BasePropsWithChildren } from '../bloomreach/types';

const addQueryParamToPath = (pathname: string, search: string): string => {
    const queryParam = new URLSearchParams(search).get('q');
    return queryParam ? `${pathname}?p=${queryParam}` : pathname;
};

const pathMap = new Map<string, number>();
const lastPathnames: string[] = [];

const shouldRestoreScroll = (pathname: string): boolean =>
    pathname.includes('/c/') ||
    pathname.includes('search-results') ||
    (pathname === '/' &&
        pathname === lastPathnames[0] &&
        lastPathnames.some((lastPathname) => lastPathname.includes('/p/')));

const restoreScrollPosition = (pathname: string) =>
    setTimeout(() => window.scrollTo(0, pathMap.get(pathname) ?? 0), 300);

const scrollTop = () => setTimeout(() => window.scrollTo(0, 0), 300);

const scrollToAnchor = (hash: string) => {
    setTimeout(() => {
        let anchor = document.querySelector(hash);
        const contentNavigation = document.querySelector(`.${contentNavigationComponentName}`);
        anchor = anchor || document.querySelector(`[name="${hash.slice(1)}"]`);

        if (anchor) {
            anchor.scrollIntoView({ block: 'start', behavior: 'instant' });
            const accordionHeading = anchor.querySelector(`.${accordionItemComponentName}__heading`);
            const accordionItem = anchor.querySelector(`.${accordionItemComponentName}__item`);
            if (accordionHeading && !accordionItem) {
                (accordionHeading as HTMLElement).click();
            }
        }

        if (contentNavigation) {
            window.scrollBy(0, -CONTENT_NAVIGATION_HEIGHT);
        }
    }, 700);
};

const scroll = (pathname: string, hash: string) => {
    if (history.state?.usr !== 'preventScroll') {
        if (shouldRestoreScroll(pathname)) {
            restoreScrollPosition(pathname);
        } else if (hash) {
            scrollToAnchor(hash);
        } else {
            scrollTop();
        }
    }

    lastPathnames.push(pathname);
    if (lastPathnames.length > 2) {
        lastPathnames.shift();
    }
};

const handleLinkClick = (event: MouseEvent, pathname: string) => {
    const target = event.target as HTMLAnchorElement;
    if (target.tagName === 'A' && target.href.includes(pathname) && target.href.includes('#')) {
        scrollToAnchor(`#${target.href.split('#')[1]}`);
    }
};

const ScrollManager = ({ children }: BasePropsWithChildren): JSX.Element => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { pathname, search, hash } = useLocation();
    const normalizedPathname = addQueryParamToPath(removeLocaleFromPathname(pathname), search);

    const updatePathMap = debounce(() => {
        if (
            normalizedPathname.includes('/c/') ||
            normalizedPathname.includes('search-results') ||
            normalizedPathname === '/'
        ) {
            pathMap.set(normalizedPathname, window.scrollY);
        }
    }, 200);

    const onClick = useCallback(
        (event: MouseEvent) => {
            handleLinkClick(event, normalizedPathname);
        },
        [normalizedPathname]
    );

    useEffect(() => {
        const observer = new MutationObserver(
            debounce(() => {
                scroll(normalizedPathname, hash);
                observer.disconnect();
                window.addEventListener('scroll', updatePathMap);
                window.addEventListener('click', onClick);
            }, 200)
        );

        if (containerRef.current) {
            observer.observe(containerRef.current, {
                childList: true,
                subtree: true,
                attributes: true,
                characterData: true,
            });
        }

        return () => {
            observer.disconnect();
            window.removeEventListener('scroll', updatePathMap);
            window.removeEventListener('click', onClick);
        };
    }, [normalizedPathname]);

    useEffect(() => {
        if (hash) {
            scrollToAnchor(hash);
        }
    }, [hash]);

    return <div ref={containerRef}>{children}</div>;
};

export default ScrollManager;
