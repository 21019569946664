import { createQueryKeys } from '@lukemorales/query-key-factory';
import { NeededPriceType } from '../../api/types/PriceType';

export const products = createQueryKeys('products', {
    exitRecommendations: (sku: string, country: string) => ({
        queryKey: [sku, country],
    }),
    productsById: (ids: string[], neededPriceType?: NeededPriceType, relatedSku?: string) => ({
        queryKey: [neededPriceType, relatedSku, ...ids],
    }),
    productsByKey: (keys: string[], neededPriceType?: NeededPriceType, relatedSku?: string) => ({
        queryKey: [neededPriceType, relatedSku, ...keys],
    }),
    productsBySku: (
        skus: string[],
        locale: string,
        country: string,
        currency: string,
        neededPriceType?: NeededPriceType
    ) => ({
        queryKey: [skus, neededPriceType, locale, country, currency],
    }),
    queryProducts: (
        identifiers: string[],
        country: string,
        currency: string,
        locale: string,
        neededPriceType?: NeededPriceType
    ) => ({
        queryKey: [identifiers, locale, country, currency, neededPriceType],
    }),
});
