import isEmpty from 'lodash/isEmpty';
import ApiClient from './ApiClient';
import { RootStore } from '../store';
import { AddressForm } from './types/Address';
import {
    AddLineItemRequest,
    CartQueryContextParams,
    DeleteCartRequest,
    GetCartRequest,
    RemoveDiscountCodeRequest,
    RemoveDiscountCodeResponse,
    SetLineItemQuantityRequest,
    InitiateExpressCheckoutRequest,
    InitiateExpressCheckoutResponse,
    SetAddressExpressCheckoutResponse,
    SetAddressExpressCheckoutRequest,
    AddDiscountCodeRequest,
    AddDiscountCodeError,
    AddDiscountCodeResponse,
    DeleteCartResponse,
    GetCartResponse,
    AddLineItemResponse,
    GetInstallmentPlanRequest,
    GetInstallmentPlanResponse,
    RestoreCartRequest,
    RestoreCartResponse,
    SetPartnerIdRequest,
    SetPartnerIdResponse,
    CompleteCheckoutResponse,
    CompleteCheckoutRequest,
    SetRequiredPaymentFieldRequest,
    SetRequiredPaymentFieldResponse,
    UnfreezeCartRequest,
    UnfreezeCartResponse,
    SetLineItemQuantityResponse,
    RemoveLineItemResponse,
    RemoveLineItemRequest,
    SetAddressRequest,
    SetAddressResponse,
    SelectPaymentRequest,
    SelectPaymentResponse,
    CreatePaymentRequest,
    CreatePaymentResponse,
    GetPaymentRequest,
    GetPaymentResponse,
    InitiateCheckoutResponse,
    InitiateCheckoutRequest,
    GetExpressCheckoutConfigRequest,
    GetExpressCheckoutConfigResponse,
} from './types/CartQueries';
import CartMapper from './mapper/CartMapper';
import { Cart, InstallmentPlanProps } from './types/ClientCart';
import ensureNumber from '../helper/ensureNumber';
import PQueue from 'p-queue';
import { BrxRelationType } from '@mediashop/app/bloomreach/types';
import { mapBrxRelationTypeToGatewayRelationType } from './mapper/ProductRelationMapper';
import { CartDto } from './dtos/cart/cartDto';
import { PaymentMethodName } from '../domain/Payment';
import { SubscriptionInterval } from '@mediashop/catalog-base/pattern/molecule/AboSelector/AboSelector';

type AddLineItemProps = {
    lineItemKey?: string;
    partner?: string;
    quantity: number | string;
    relationType?: BrxRelationType;
    sku: string;
    subscriptionInterval?: SubscriptionInterval;
    wkoSku?: string;
    facebookTrackingId?: string;
};

interface PaymentUrls {
    errorUrl: string;
    returnUrl: string;
    cancelUrl: string;
}

export default class CartClient {
    private apiClient: ApiClient;
    private store: RootStore;
    private queue: PQueue;

    constructor(apiClient: ApiClient, store: RootStore) {
        this.apiClient = apiClient;
        this.store = store;
        this.queue = new PQueue({ concurrency: 1 });
    }

    private getContextParams(): CartQueryContextParams {
        const { context } = this.store.getState();
        return {
            currency: context.currency,
            country: context.country,
            locale: context.locale,
        };
    }

    private mapCart(cart: CartDto): Cart {
        return CartMapper.mapCart(cart);
    }

    async addLineItem({
        facebookTrackingId,
        lineItemKey,
        partner,
        quantity = 1,
        relationType,
        sku,
        wkoSku,
        subscriptionInterval,
    }: AddLineItemProps): Promise<Cart> {
        const res = await this.queue.add(() =>
            this.apiClient.query<AddLineItemRequest, AddLineItemResponse>(
                'AddLineItem',
                {
                    ...this.getContextParams(),
                    sku,
                    quantity: ensureNumber(quantity),
                    partner,
                    productRelationType: mapBrxRelationTypeToGatewayRelationType(relationType),
                    relatedLineItemKey: lineItemKey,
                    subscriptionSelection: subscriptionInterval,
                    wkoSku,
                },
                {
                    facebookTrackingId,
                }
            )
        );
        return this.mapCart(res);
    }

    async setLineItemQuantity(lineItemKey: string, quantity: number): Promise<Cart> {
        const { locale } = this.getContextParams();
        const res = await this.queue.add(() =>
            this.apiClient.query<SetLineItemQuantityRequest, SetLineItemQuantityResponse>('SetLineItemQuantity', {
                locale,
                lineItemKey,
                quantity,
            })
        );
        return this.mapCart(res);
    }

    async removeLineItem(lineItemKey: string): Promise<Cart> {
        const { locale } = this.getContextParams();
        const res = await this.queue.add(() =>
            this.apiClient.query<RemoveLineItemRequest, RemoveLineItemResponse>('RemoveLineItem', {
                lineItemKey,
                locale,
            })
        );

        return this.mapCart(res);
    }

    async getCart(): Promise<Cart | null> {
        const { locale } = this.getContextParams();
        const res = await this.apiClient.query<GetCartRequest, GetCartResponse>('GetCart', {
            locale,
        });
        if (isEmpty(res)) {
            return null;
        }
        return this.mapCart(res);
    }

    async deleteCart(): Promise<void> {
        await this.apiClient.query<DeleteCartRequest, DeleteCartResponse>('DeleteCart');
    }

    async setAddresses(address: AddressForm, isGuestCheckout?: boolean): Promise<Cart> {
        const contextParams = this.getContextParams();

        address.hasShipping = address.hasShipping === 'true';

        const res = await this.queue.add(() =>
            this.apiClient.query<SetAddressRequest, SetAddressResponse>('SetAddress', {
                ...contextParams,
                ...address,
                billing_postalCode: address.billing_postalCode?.replaceAll('_', ''),
                shipping_postalCode: address.shipping_postalCode?.replaceAll('_', ''),
                billing_isCompany:
                    address.billing_isCompany !== undefined ? address.billing_isCompany === 'true' : undefined,
                shipping_isCompany:
                    address.shipping_isCompany !== undefined ? address.shipping_isCompany === 'true' : undefined,
                shopCountry: contextParams.country,
                orderAsGuest: isGuestCheckout,
            })
        );

        return this.mapCart(res);
    }

    createPayment(urls: PaymentUrls, locale: string, facebookTrackingId: string) {
        return this.queue.add(() =>
            this.apiClient.query<CreatePaymentRequest, CreatePaymentResponse>(
                'CreatePayment',
                {
                    ...urls,
                    locale,
                },
                { facebookTrackingId }
            )
        );
    }

    getPayment() {
        return this.apiClient.query<GetPaymentRequest, GetPaymentResponse>('GetPayment');
    }

    async selectPayment(paymentMethod: PaymentMethodName, locale: string, facebookTrackingId: string) {
        const cart = await this.apiClient.query<SelectPaymentRequest, SelectPaymentResponse>(
            'SelectPayment',
            {
                paymentMethod,
                locale,
            },
            { facebookTrackingId }
        );
        return this.mapCart(cart);
    }

    async addDiscountCode(discountCode: string, partner?: string): Promise<Cart> {
        const res = await this.apiClient.query<AddDiscountCodeRequest, AddDiscountCodeResponse, AddDiscountCodeError>(
            'AddDiscountCode',
            {
                ...this.getContextParams(),
                discountCode,
                partner,
            }
        );
        return this.mapCart(res);
    }

    async removeDiscountCode(discountCode: string): Promise<Cart> {
        const { locale } = this.getContextParams();
        const res = await this.apiClient.query<RemoveDiscountCodeRequest, RemoveDiscountCodeResponse>(
            'RemoveDiscountCode',
            {
                discountCode,
                locale,
            }
        );
        return this.mapCart(res);
    }

    async getInstallmentPlan(dateOfBirth: string): Promise<InstallmentPlanProps> {
        const { locale } = this.getContextParams();
        return await this.apiClient.query<GetInstallmentPlanRequest, GetInstallmentPlanResponse>('GetInstallmentPlan', {
            dateOfBirth,
            locale,
        });
    }

    /**
     * Restore a cart by it´s id. Used for cart abandonments.
     * @param cartId id of the cart to be restored
     * @returns cart
     */
    async restoreCart(cartId: string, partner: string): Promise<Cart> {
        const { locale, currency } = this.getContextParams();

        const res = await this.apiClient.query<RestoreCartRequest, RestoreCartResponse>('RestoreCart', {
            cartId,
            currency,
            locale,
            partner,
        });
        return this.mapCart(res);
    }

    async setPartnerId(partner: string): Promise<string> {
        const { locale } = this.getContextParams();
        const res = await this.apiClient.query<SetPartnerIdRequest, SetPartnerIdResponse>('SetPartnerId', {
            locale,
            partner,
        });
        return res.customFields.partnerId;
    }

    getExpressCheckoutConfig(locale: string) {
        return this.apiClient.query<GetExpressCheckoutConfigRequest, GetExpressCheckoutConfigResponse>(
            'GetExpressCheckoutConfiguration',
            {
                locale,
            }
        );
    }

    initiateCheckout(locale: string) {
        return this.apiClient.query<InitiateCheckoutRequest, InitiateCheckoutResponse>('InitiateCheckout', {
            locale,
        });
    }

    initiateExpressCheckout(
        paymentMethod: 'PAYPAL_EXPRESS',
        urls: PaymentUrls,
        locale: string,
        facebookTrackingId: string
    ) {
        return this.apiClient.query<InitiateExpressCheckoutRequest, InitiateExpressCheckoutResponse>(
            'InitiateExpressCheckout',
            {
                ...urls,
                locale,
                paymentMethod,
            },
            { facebookTrackingId }
        );
    }

    async setAddressExpressCheckout(): Promise<Cart> {
        const { locale } = this.getContextParams();
        const res = await this.apiClient.query<SetAddressExpressCheckoutRequest, SetAddressExpressCheckoutResponse>(
            'SetAddressExpressCheckout',
            {
                locale,
            }
        );
        return this.mapCart(res);
    }

    async completeCheckout(
        comment: string | undefined,
        installmentOptIn: boolean,
        subscribeToNewsletter?: boolean
    ): Promise<CompleteCheckoutResponse> {
        const { locale } = this.getContextParams();
        return await this.apiClient.query<CompleteCheckoutRequest, CompleteCheckoutResponse>('CompleteCheckout', {
            comment: comment?.length ? comment : undefined,
            installmentOptIn: installmentOptIn || undefined,
            locale,
            subscribeToNewsletter,
        });
    }

    async setRequiredPaymentField(dateOfBirth?: string, phone?: string): Promise<void> {
        const { locale } = this.getContextParams();
        await this.apiClient.query<SetRequiredPaymentFieldRequest, SetRequiredPaymentFieldResponse>(
            'SetRequiredPaymentField',
            {
                locale,
                dateOfBirth: dateOfBirth?.length ? dateOfBirth : undefined,
                phone: phone?.length ? phone : undefined,
            }
        );
    }

    async unfreezeCart(locale: string): Promise<Cart> {
        const res = await this.apiClient.query<UnfreezeCartRequest, UnfreezeCartResponse>('UnfreezeCart', {
            locale,
        });
        return this.mapCart(res);
    }
}
