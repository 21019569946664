import LinkHelper from '@mediashop/app/helper/LinkHelper';
import { usePartner } from '@mediashop/app/hooks/usePartner';
import { useEffect, useState } from 'react';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import InfoBannerCommon from '../InfoBannerCommon';
import { InfoBannerCustomProps } from './index';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { emptyDesktopTextCompound, isTextCompoundAvailable } from '../../atom/TextCompound/functions';
import BloomreachHtml, { BrxHtml } from '@mediashop/app/components/BloomreachHtml';
import ContentWrapper from '../../atom/ContentWrapper';

const updateTextCompoundFieldWithPartnerId = (
    field: BrxHtml,
    partnerId: string,
    addPartnerIdToUrl: (htmlString: string, partnerId: string) => string
): BrxHtml => {
    if (typeof field === 'object') {
        field.value = addPartnerIdToUrl(field.value!, partnerId);
    } else {
        field = addPartnerIdToUrl(field, partnerId);
    }
    return field as BrxHtml;
};

const componentName = 'info-banner-custom';

/**
 * This component renders a dismisable banner.
 */
function InfoBannerCustom({
    anchor,
    icon,
    id,
    style,
    showCloseTrigger,
    backgroundColor,
    fontcolor: textColor,
    textCompound,
}: InfoBannerCustomProps): JSX.Element | null {
    const [device, setDevice] = useState('desktop');
    const deviceType = useDeviceType();

    useEffect(() => {
        setDevice(deviceType);
    }, [deviceType]);

    const { partnerId } = usePartner();

    const isMobileDevice = device === 'mobile';

    const addPartnerIdToUrl = (htmlString: string, partnerId: string) => {
        const urlPattern = /(\bhttps?:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
        const results = htmlString.match(urlPattern);

        results?.forEach((url) => {
            if (!LinkHelper.isExternalUrl(url)) {
                const modifiedUrl = new URL(url);
                modifiedUrl.searchParams.append('partner', partnerId);
                htmlString = htmlString.replace(`"${url}"`, `"${modifiedUrl.toString()}"`);
            }
        });
        return htmlString;
    };

    const isDesktopTextEmpty = emptyDesktopTextCompound(isMobileDevice, textCompound);
    if (!isTextCompoundAvailable(textCompound) || isDesktopTextEmpty) {
        return SKIP_RENDER;
    }

    if (partnerId) {
        textCompound.desktop = updateTextCompoundFieldWithPartnerId(textCompound.desktop, partnerId, addPartnerIdToUrl);
        textCompound.mobile = updateTextCompoundFieldWithPartnerId(textCompound.mobile, partnerId, addPartnerIdToUrl);
    }

    const mobileText =
        typeof textCompound.mobile === 'string' || !textCompound.mobile?.value
            ? textCompound.desktop
            : textCompound.mobile;

    return (
        <InfoBannerCommon
            anchor={anchor}
            backgroundColor={backgroundColor}
            textColor={textColor}
            dismissalKey={id}
            icon={icon}
            showCloseTrigger={showCloseTrigger}
            style={style}
            text={
                <ContentWrapper>
                    <BloomreachHtml
                        html={mobileText}
                        className={`${componentName}__html-content ${componentName}__html-content-mobile`}
                        style={{ color: textColor ? textColor : undefined }}
                    />
                    <BloomreachHtml
                        html={textCompound.desktop}
                        className={`${componentName}__html-content ${componentName}__html-content-desktop`}
                        style={{ color: textColor ? textColor : undefined }}
                    />
                </ContentWrapper>
            }
        />
    );
}

export default InfoBannerCustom;
