import { VariantAsset } from '@mediashop/app/api/types/ClientProduct';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import CroppedImage from '@mediashop/base/pattern/atom/CroppedImage';
import { CroppedImageVariant } from '@mediashop/base/types/imageVariants';
import SwiperCore from 'swiper';
import { SwiperSlide } from 'swiper/react';
import Slider from '@mediashop/base/pattern/molecule/Slider';

const componentName = 'product-details-gallery';

type Props = {
    onBeforeIndexChange: (currentSlide: number) => void;
    openMobileImageGallery?: () => void;
    pictures?: VariantAsset[];
    productName: string;
    setMainSlider: (value: SwiperCore) => void;
    thumbnailSlider?: SwiperCore;
};

export function ProductDetailsGallery({
    pictures = [],
    productName,
    thumbnailSlider,
    openMobileImageGallery,
    onBeforeIndexChange,
    setMainSlider,
}: Props): JSX.Element {
    const deviceType = useDeviceType();
    const isMobileDevice = deviceType === 'mobile';

    return (
        <div className={componentName}>
            <Slider
                className={componentName}
                loop
                onSwiper={setMainSlider}
                thumbs={{ swiper: thumbnailSlider && !thumbnailSlider.destroyed ? thumbnailSlider : null }}
                slidesPerView={1}
                spaceBetween={3}
                onSlideChange={({ realIndex }) => onBeforeIndexChange(realIndex)}
                onClick={isMobileDevice && openMobileImageGallery ? () => openMobileImageGallery() : undefined}
                showArrows={pictures?.length > 1}
            >
                {pictures.map((image, key) => (
                    <div
                        key={`mainslider-${image.key}`}
                        className={`${componentName}__content-container background--default`}
                    >
                        {image?.sources && (
                            <SwiperSlide key={`mainslider-${image.key}-item`}>
                                <CroppedImage
                                    altText={productName}
                                    className={`${componentName}__image`}
                                    src={image.sources[0]?.uri}
                                    variant={CroppedImageVariant.PDPBigImage}
                                    fetchPriority={key === 0 ? 'high' : 'low'}
                                />
                            </SwiperSlide>
                        )}
                    </div>
                ))}
            </Slider>
        </div>
    );
}
