import { VariantAsset } from '@mediashop/app/api/types/ClientProduct';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { lazy } from 'react';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const MobileImageGalleryMolecule = lazy(() => lazyRetry(() => import('./MobileImageGallery')));

export type MobileImageGalleryProps = BaseProps & {
    initIndex: number;
    images: VariantAsset[];
    closeGallery: () => void;
};

const MobileImageGallery = (props: MobileImageGalleryProps): JSX.Element => (
    <LazyComponent>
        <MobileImageGalleryMolecule {...props} />
    </LazyComponent>
);

export default MobileImageGallery;
