import { lazy } from 'react';
import { Product, Variant } from '@mediashop/app/api/types/ClientProduct';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxRelationType } from '@mediashop/app/bloomreach/types';

export type PreCheckoutFlyoutProps = BaseProps & {
    activeVariant: Variant;
    onClose: () => void;
    product: Product;
    productRelationType?: BrxRelationType;
    errorMessage?: string;
};

import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const PreCheckoutFlyoutMolecule = lazy(() => lazyRetry(() => import('./PreCheckoutFlyout')));

const PreCheckoutFlyout = (props: PreCheckoutFlyoutProps): JSX.Element => (
    <LazyComponent>
        <PreCheckoutFlyoutMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.PreCheckoutFlyout', PreCheckoutFlyout, 'catalog-base');
