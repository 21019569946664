import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { lazy, ReactElement } from 'react';
import { MainNavigationCustomNavigationChildItemProps } from '../../types';

const DesktopSubNavigationLazy = lazy(() => lazyRetry(() => import('./DesktopSubNavigation')));

export type DesktopSubNavigationProps = BaseProps & {
    closeSubNavigation: () => void;
    isLastItem: boolean;
    subNavigation: Array<MainNavigationCustomNavigationChildItemProps>;
};

const DesktopSubNavigation = (props: DesktopSubNavigationProps): ReactElement => (
    <LazyComponent>
        <DesktopSubNavigationLazy {...props} />
    </LazyComponent>
);

export default DesktopSubNavigation;
