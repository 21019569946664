import icons from '@mediashop/base/icons';
import { countries, locales } from '@mediashop/base/assets/data/countries';
import { MappedCountry, MappedLocale } from '../types/country-switcher';

const getResolvedFlagIconName = (countryCode?: string): string => {
    if (!countryCode) {
        return 'worldFlag';
    }
    return Object.keys(icons).includes(`${countryCode.toLowerCase()}Flag`)
        ? `${countryCode.toLowerCase()}Flag`
        : 'worldFlag';
};

export const getMappedCountry = (countryCode: string) => ({
    value: countryCode,
    label: countries.find((country) => country.code === countryCode)?.name,
    iconName: getResolvedFlagIconName(countryCode),
});

const getMatchingFrontendLocale = (localeCode: string) =>
    locales.find((locale) => locale.value.split('-')[0] === localeCode.split('-')[0]);

const getMatchingProjectLocale = (localeCode: string | undefined, configLocales: string[]) =>
    localeCode ? configLocales.find((locale) => locale.split('-')[0] === localeCode.split('-')[0]) : undefined;

export const getMappedLocale = (localeCode: string, configLocales: string[]) => {
    // we get the first config locale from projects.yml to act as a default
    const defaultLocale = configLocales[0];

    const availableOptions = configLocales
        .map((locale) => getMatchingFrontendLocale(locale))
        .filter((locale) => locale) as Exclude<ReturnType<typeof getMatchingFrontendLocale>, undefined>[];

    /**
     * The matching locale is the one we have in the frontend available, like de-DE, fr-FR, etc.
     * This is used for displaying translations only
     */
    const matchingLocale = getMatchingFrontendLocale(localeCode);

    const isMatchingLocaleAvailable = Boolean(
        availableOptions.find((locale) => locale.value === matchingLocale?.value)
    );

    /**
     * If we don't have a matching locale, we resolve it from the defaultLocale
     * which should always exist (i.e. if the project has de-CH, we have de-DE in the frontend)
     */
    let resolvedLocale = getMatchingFrontendLocale(defaultLocale);
    if (isMatchingLocaleAvailable) {
        resolvedLocale = matchingLocale;
    }

    if (!resolvedLocale) {
        throw new Error('Could not resolve locale');
    }

    /**
     * The original locale needs to be set in projects.yml, if we have one for the `matchingLocale`,
     * for example we matched the locale to de-DE, but the project has de-CH, so we need to set de-CH
     */
    const matchingProjectLocale = getMatchingProjectLocale(matchingLocale?.value, configLocales);

    /**
     * If we don't have a matching locale, we just set it to the default for the project
     */
    const originalLocale = matchingProjectLocale ?? defaultLocale;

    return {
        value: resolvedLocale.value,
        label: resolvedLocale.country,
        iconName: getResolvedFlagIconName(resolvedLocale.iconShortCode),
        originalLocale,
    };
};

export const hasCountryAndLocale = (shippingCountries: string[], locales: string[], url: string) => {
    const shippingCountriesLowerCase = shippingCountries.map((country) => country.toLowerCase());
    const localesLowerCase = locales.map((locale) => locale.split('-')[0].toLowerCase());

    const regex = new RegExp(`/(${shippingCountriesLowerCase.join('|')})/(${localesLowerCase.join('|')})/`);
    const urlWithTrailingSlash = url + (url.endsWith('/') ? '' : '/');
    const matches = regex.exec(urlWithTrailingSlash);
    return Boolean(matches && matches.length);
};

export const getCountryOptions = (configShippingCountries: string[]): MappedCountry[] => {
    return configShippingCountries.map(getMappedCountry).filter((country) => country.value !== undefined);
};

export const getLocaleOptions = (configLocales: string[]): MappedLocale[] => {
    return configLocales
        .map((locale) => getMappedLocale(locale, configLocales))
        .filter((item) => item.value !== undefined)
        .map((item, idx, arr) => {
            // remove the duplicate locales by value
            if (arr.findIndex((item2) => item2.value === item.value) === idx) {
                return item;
            }
            return null;
        })
        .filter((item) => item !== null);
};
