import { PayPalBanner } from '@mediashop/base/pattern/organism/Checkout/Payment/PayPalBanner/PayPalBanner';
import { BrxRelationType } from '@mediashop/app/bloomreach/types';
import { Variant } from '@mediashop/app/api/types/ClientProduct';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { FormattedMessage } from 'react-intl';
import BuyButton from '@mediashop/catalog-base/pattern/molecule/BuyButton';
import Modal from '@mediashop/base/pattern/molecule/Modal';
import { DetailedProduct } from '@mediashop/app/api/types/ClientProductDetailed';
import { Money } from '@mediashop/app/api/types/Money';
import StandardDeliveryModalContent from '@mediashop/base/pattern/organism/ProductStage/StandardDeliveryModalContent';
import { BrxHtml } from '@mediashop/app/components/BloomreachHtml';
import Quantity from '@mediashop/app/domain/Quantity';
import classNames from 'classnames';
import UnitPrice from '@mediashop/base/pattern/atom/UnitPrice';
import CheaperSetPrice from './CheaperSetPrice';
import ShippingTime from '../ShippingTime/ShippingTime';
import { useShopContext } from '@mediashop/app/hooks/useShopContext';

type PurchaseDetailsProps = {
    componentName: string;
    variant: Variant;
    product?: DetailedProduct;
    cheaperSetPrice?: Money;
    shippingCostsInfo?: BrxHtml;
    isInProgress: boolean;
    handleAddToCartClick: (quantity: number) => Promise<void>;
    dontShowPreCheckoutFlyout: boolean;
    productRelationType?: BrxRelationType;
    setShowDeliveryModal: (value: boolean) => void;
    showDeliveryModal: boolean;
    markGreen: any;
    addToCartErrorMessage?: string;
};

export default function PurchaseDetails({
    componentName,
    cheaperSetPrice,
    dontShowPreCheckoutFlyout,
    handleAddToCartClick,
    isInProgress,
    product,
    productRelationType,
    setShowDeliveryModal,
    showDeliveryModal,
    shippingCostsInfo,
    variant,
    addToCartErrorMessage,
}: PurchaseDetailsProps): JSX.Element {
    const { country } = useShopContext();
    const optionQuantities = Quantity.getQuantities(1, variant.attributes.maxOrderQuantity ?? 10, 1, 1);
    const isSingleChoiceSelect = optionQuantities.length === 1 && optionQuantities[0] === 1;

    return (
        <>
            <div className={`${componentName}__buy-wrapper`}>
                <div
                    className={classNames(`${componentName}__buy`, {
                        [`${componentName}__buy--with-select`]: !isSingleChoiceSelect,
                    })}
                >
                    <BuyButton
                        className={`${componentName}__buy-button`}
                        maxQuantity={variant?.attributes?.maxOrderQuantity}
                        onClick={handleAddToCartClick}
                        isLoading={isInProgress}
                        iconName="none"
                        textId="product.addToCart"
                        goToCart={false}
                        product={product}
                        activeVariant={variant}
                        showMobileIcon={false}
                        hidePreCheckoutFlyout={isInProgress || dontShowPreCheckoutFlyout}
                        productRelationType={productRelationType}
                        addToCartErrorMessage={addToCartErrorMessage}
                    />
                </div>

                {variant.mightHaveCheaperSets && cheaperSetPrice ? (
                    <CheaperSetPrice
                        componentName={componentName}
                        cheaperSetPrice={cheaperSetPrice}
                        variant={variant}
                    />
                ) : (
                    SKIP_RENDER
                )}
            </div>

            <div className={`${componentName}__hints`}>
                {typeof variant?.attributes?.shippingTime === 'string' && (
                    <div className={`${componentName}__delivery-text`}>
                        <ShippingTime className={componentName} variant={variant} />
                    </div>
                )}

                <div className={`${componentName}__price-hint`}>
                    <UnitPrice variant={variant} />
                    <div>
                        <FormattedMessage id="product.priceHint" />
                    </div>
                    <span
                        className={`${componentName}__price-hint-link`}
                        onClick={() => setShowDeliveryModal(!showDeliveryModal)}
                    >
                        <FormattedMessage id="product.deliveryHint" />
                    </span>
                    <Modal
                        isOpen={showDeliveryModal}
                        onRequestClose={() => setShowDeliveryModal(false)}
                        overlayClassName="modal__overlay-bg"
                    >
                        <StandardDeliveryModalContent htmlContent={shippingCostsInfo} />
                    </Modal>
                </div>
            </div>

            <PayPalBanner showText={false} showBanner={country === 'DE'} />
        </>
    );
}
