import { BackgroundProps, BaseProps, BrxRelationType } from '@mediashop/app/bloomreach/types';
import StarRating from '@mediashop/base/pattern/atom/StarRating';
import classNames from 'classnames';
import { FormattedNumber } from 'react-intl';
import ShippingTime from '../../ProductDetails/Content/ShippingTime/ShippingTime';
import { ProductWithActiveVariant } from '@mediashop/app/api/types/ClientProduct';
import { BadgeObject } from '../../../../types/badges';
import { useChannelSettings } from '@mediashop/app/bloomreach/useChannelSettings';
import ProductStageInfos from '@mediashop/catalog-base/pattern/organism/ProductStage/Infos';
import { getVariant } from '../helper';
import ProductDescription from './ProductDescription/ProductDescription';
import ProductButton from './ProductButton/ProductButton';

const componentName = 'product-data';

type ProductDataProps = BaseProps & {
    product: ProductWithActiveVariant;
    backgroundColor?: BackgroundProps;
    dealOfTheDay?: boolean;
    description?: string;
    disabled?: boolean;
    isExitGrid?: boolean;
    partnerId?: string;
    productRelationType?: BrxRelationType;
    status: string;
    detailBadges: BadgeObject[];
    hasSaleBadge: boolean;
    handleDisableAllButtons?: (disable: boolean) => void;
    goToProduct?: (event: unknown) => void;
};

function ProductData({
    product,
    backgroundColor,
    dealOfTheDay = false,
    description,
    disabled = false,
    isExitGrid = false,
    partnerId: nextPartnerId,
    productRelationType,
    status,
    detailBadges,
    hasSaleBadge,
    handleDisableAllButtons,
    goToProduct,
}: ProductDataProps): JSX.Element {
    const { showReviews } = useChannelSettings();
    const currentVariant = getVariant(product);
    const showDeliveryTime =
        currentVariant &&
        Boolean(currentVariant?.attributes?.deliveryTimeFrom) &&
        Boolean(currentVariant?.attributes?.deliveryTimeUntil);
    const showFrom = !product.activeVariant && product?.variants?.length > 1;
    const stars = currentVariant?.attributes.ratingValueLocalized ?? 0;
    const reviewsAvailable = showReviews && stars > 0;
    const badgesAvailable = detailBadges.length > 0;
    const activeInfos = [reviewsAvailable, badgesAvailable].filter(Boolean).length;

    return (
        <div
            className={classNames(`${componentName}__details-container`, {
                [`${componentName}__details-container--has-delivery`]: showDeliveryTime,
            })}
        >
            {/** Description */}
            <ProductDescription
                product={product}
                backgroundColor={backgroundColor}
                activeInfos={activeInfos}
                description={description}
            />

            {/** Ratings */}
            {reviewsAvailable && (
                <div className={`${componentName}__reviews-holder`}>
                    <StarRating
                        className={`${componentName}__rating`}
                        rating={stars}
                        ratingText={
                            <span className={`${componentName}__review-count`}>
                                <FormattedNumber value={currentVariant?.attributes.ratingCountLocalized} />
                            </span>
                        }
                    />
                </div>
            )}

            {/** Details */}
            <div className={`${componentName}__details`}>
                <ProductStageInfos
                    className={componentName}
                    activeVariant={currentVariant}
                    isLoading={status === 'loading'}
                    showCheapestPrice={!product.activeVariant}
                    showFrom={showFrom}
                    hasVariants={product.variants.length > 1}
                    detailBadges={detailBadges}
                    hasSaleBadge={hasSaleBadge}
                />

                {/** Delivery time */}
                <ShippingTime variant={currentVariant} className={`${componentName}`} />

                {/** Add to cart button */}
                <ProductButton
                    product={product}
                    dealOfTheDay={dealOfTheDay}
                    disabled={disabled}
                    partnerId={nextPartnerId}
                    productRelationType={productRelationType}
                    handleDisableAllButtons={handleDisableAllButtons}
                    goToProduct={goToProduct}
                    isExitGrid={isExitGrid}
                />
            </div>
        </div>
    );
}

export default ProductData;
