import useNostoSearchAnalytics from '@mediashop/app/hooks/useNostoSearchAnalytics';
import { useOutsideClicked } from '@mediashop/app/hooks/useOutsideClicked';
import { useProject } from '@mediashop/app/hooks/useProject';
import Icon from '@mediashop/base/pattern/atom/Icon';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState, type MouseEvent, type KeyboardEvent } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { useLocation } from 'react-router-dom';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { Product } from '@mediashop/app/api/types/ClientProduct';
import { useAutoSuggest } from '@mediashop/app/queries/search/useAutoSuggest';
import { useLoader } from '@mediashop/app/store/loader';
import { EMPTY_STRING, SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useDebounce } from '../../../hooks/useDebounce';
import { createPortal } from 'react-dom';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import SearchInput from './SearchInput/SearchInput';
import SearchSuggestion from './SearchSuggestion/SearchSuggestion';
import { useSearchProducts } from '@mediashop/app/queries/search/useSearchProducts';
import { useShopContext } from '@mediashop/app/hooks/useShopContext';
import { getSearchParam } from '../Product/helper';
import { useNavigate } from '@mediashop/app/hooks/useNavigate';

const MAX_SUGGESTIONS_DISPLAY = 5;

type SearchBarProps = BaseProps & {
    query?: string;
    isMainNavigationCustom?: boolean;
};

const componentName = 'search-bar';

// eslint-disable-next-line max-lines-per-function
function SearchBar({ query, isMainNavigationCustom }: SearchBarProps) {
    const [device, setDevice] = useState('desktop');
    const deviceType = useDeviceType();
    useEffect(() => setDevice(deviceType), [deviceType]);
    const isMobileDevice = device === 'mobile' || (device === 'tablet' && isMainNavigationCustom);

    const navigate = useNavigate();
    const location = useLocation();

    const { searchProvider } = useProject();
    const { country, currency, locale } = useShopContext();
    const { productClient } = useLoader();

    const [searchQuery, setSearchQuery] = useState<string>(query ?? EMPTY_STRING);
    const debouncedSearchQuery = useDebounce(searchQuery, 400);

    const searchRef = useRef<HTMLInputElement>(null);
    const searchBarRef = useRef<HTMLInputElement>(null);
    const searchSuggestionRef = useRef<HTMLDivElement>(null);

    const [isSuggestionActive, setIsSuggestionActive] = useState(false);
    const [activeSuggestionWidth, setActiveSuggestionWidth] = useState<number>(0);

    const { trackSearch, trackSearchOnSubmit, trackProductClick } = useNostoSearchAnalytics();

    const calcActiveSuggestionWidth = (): number => {
        let contentWrapper: HTMLElement | null;
        let logo: HTMLElement | null;

        if (isMainNavigationCustom) {
            contentWrapper = document.querySelector<HTMLElement>('.main-navigation-custom__inner');
            logo = document.querySelector<HTMLElement>('.main-navigation-custom__logo');
        } else {
            contentWrapper = document.querySelector<HTMLElement>('.main-navigation__wrapper');
            logo = document.querySelector<HTMLElement>('.header-logo__desktop');
        }

        if (!contentWrapper || !logo) {
            // fallback max-width
            return 1000;
        }

        const mainNavigationWidth = contentWrapper.getBoundingClientRect().width;
        const mainNavigationGap = parseInt(getComputedStyle(contentWrapper).getPropertyValue('column-gap'), 10) ?? 20;
        const logoWidth = logo.getBoundingClientRect().width;

        return mainNavigationWidth - logoWidth - mainNavigationGap;
    };

    /* toggle classes (e.g. to make the background darker when entering a search term) */
    const toggleClasses = useCallback(
        (val: boolean) => {
            document.querySelector('.desktop-menu')?.classList[val ? 'add' : 'remove']('pointer-events--disabled');
            document
                .getElementById('app')
                ?.classList[val && isMobileDevice ? 'add' : 'remove']('pointer-events--disabled');

            const scrollLockClassName = isMainNavigationCustom
                ? 'scroll-lock--search-bar-custom'
                : 'scroll-lock--search-bar';
            document.querySelector('body')?.classList[val ? 'add' : 'remove'](scrollLockClassName);

            if (val) {
                /* calculate the width of the suggestion list again because when the body gets the position fix, the browser hides the scrollbar  */
                const newWidth = calcActiveSuggestionWidth();
                setActiveSuggestionWidth(newWidth);
            }
        },
        [isMobileDevice]
    );

    useEffect(() => {
        const setActiveSuggestionWidthOnResize = () => {
            const newWidth = calcActiveSuggestionWidth();
            setActiveSuggestionWidth(newWidth);
        };

        setActiveSuggestionWidthOnResize();

        window.addEventListener('resize', setActiveSuggestionWidthOnResize);

        return () => {
            window.removeEventListener('resize', setActiveSuggestionWidthOnResize);
        };
    }, []);

    /* handle search products */
    const {
        products: searchResponse,
        totalProductCount: searchResultsCount,
        landingPage,
    } = useSearchProducts({
        searchText: debouncedSearchQuery,
        nextPageIndex: 0,
        enabled: debouncedSearchQuery.length >= 2,
    });

    /* handle get autocomplete suggestions */
    const { data: { keywords = [], products: productSuggestions = [], totalProductCount } = {} } = useAutoSuggest({
        searchText: debouncedSearchQuery,
        limit: MAX_SUGGESTIONS_DISPLAY,
        enabled: debouncedSearchQuery.length >= 2,
    });

    useEffect(() => {
        if (totalProductCount !== undefined) {
            trackSearch({
                type: 'autocomplete',
                query: debouncedSearchQuery,
                products: productSuggestions,
                totalProductCount,
                isKeyword: false,
                limit: MAX_SUGGESTIONS_DISPLAY,
                offset: 0,
            });
        }
    }, [productSuggestions, totalProductCount]);

    useEffect(() => {
        if (isMobileDevice && isSuggestionActive) {
            return;
        }
        setIsSuggestionActive(isMobileDevice ? Boolean(searchQuery.length) : searchQuery.length >= 2);
    }, [isMobileDevice, isSuggestionActive, searchQuery]);

    useEffect(() => {
        if (isSuggestionActive) {
            toggleClasses(true);
        } else {
            toggleClasses(false);
        }
    }, [isSuggestionActive, toggleClasses]);

    const closeSearchDropdown = () => {
        setIsSuggestionActive(false);
        setSearchQuery('');
        toggleClasses(false);
    };

    useOutsideClicked([searchBarRef, searchSuggestionRef], () => {
        closeSearchDropdown();
    });

    const goToPage = (page: string) => {
        navigate(page);
        closeSearchDropdown();
    };

    const goToSearchResultsPage = async (query?: string, useLandingPage: boolean = false) => {
        const effectiveSearchQuery = query ?? searchQuery;

        if (effectiveSearchQuery?.length < 2) {
            return;
        }
        trackSearchOnSubmit(effectiveSearchQuery);

        const { landingPage } = await productClient.searchProducts(searchQuery, country, currency, locale, 30, 0);

        if (useLandingPage && landingPage) {
            window.location.href = landingPage.url;
            return;
        }

        const encodedSearchQuery = encodeURIComponent(effectiveSearchQuery);
        const isOnSearchPage = location.pathname.includes('search-results');
        let searchPageLoc = `search-results?q=${encodedSearchQuery}`;
        if (query && searchProvider === 'Nosto') {
            searchPageLoc += `&keyword=${query}`;
        }
        if (!isOnSearchPage) {
            goToPage(searchPageLoc);
        } else {
            /*
             * we need to reload the page because the view pixel doesn't get fired
             * if we're already there otherwise
             */
            window.location.href = searchPageLoc;
        }
    };

    const handleProductClick = (event: MouseEvent<HTMLAnchorElement>, product: Product) => {
        event.preventDefault();
        trackProductClick('autocomplete', product);
        navigate({
            pathname: `p/${product.slug}`,
            search: getSearchParam(product),
        });
        setSearchQuery(EMPTY_STRING);
        if (isMobileDevice) {
            setIsSuggestionActive(false);
        }
    };

    const searchSubmit = (event?: KeyboardEvent<HTMLInputElement>) => {
        if (!event?.key) {
            goToSearchResultsPage(undefined, true);
        } else if (event?.key === 'Enter') {
            event.preventDefault();
            goToSearchResultsPage(undefined, true);
        }
    };

    useEffect(
        () => () => {
            closeSearchDropdown();
        },
        [location.pathname]
    );

    return (
        <div ref={searchRef} className={`${componentName}__wrapper`}>
            {/*
             * Search Input
             */}
            <div className={componentName}>
                <div className={`${componentName}__input-container`} ref={searchBarRef}>
                    <SearchInput
                        searchQuery={!isMobileDevice ? searchQuery : ''}
                        debouncedSearchQuery={debouncedSearchQuery}
                        setSearchQuery={setSearchQuery}
                        searchSubmit={searchSubmit}
                        isMainNavigationCustom={isMainNavigationCustom}
                    />
                </div>
            </div>

            {!isMobileDevice && isSuggestionActive ? (
                <SearchSuggestion
                    keywords={keywords}
                    suggestedProducts={productSuggestions}
                    searchResultsCount={searchResultsCount}
                    searchQuery={searchQuery}
                    goToSearchResultsPage={goToSearchResultsPage}
                    landingPage={landingPage}
                    searchResponse={searchResponse}
                    handleProductClick={handleProductClick}
                    activeSuggestionWidth={activeSuggestionWidth}
                    searchSuggestionRef={searchSuggestionRef}
                />
            ) : (
                SKIP_RENDER
            )}

            {/*
             * Mobile Search Portal
             */}
            {isMobileDevice && isSuggestionActive ? (
                <>
                    {createPortal(
                        <div
                            className={classNames(`${componentName}__search-overlay-portal`, {
                                [`${componentName}__search-overlay-portal--custom`]: isMainNavigationCustom,
                            })}
                        >
                            <div className={componentName}>
                                <div className={`${componentName}__input-container`}>
                                    <button
                                        onClick={() => closeSearchDropdown()}
                                        className={`${componentName}__close-icon`}
                                    >
                                        <Icon name="ArrowLeft" />
                                    </button>

                                    <div ref={searchBarRef} className={`${componentName}__input`}>
                                        <SearchInput
                                            clearSearch={() => setSearchQuery('')}
                                            searchQuery={searchQuery}
                                            debouncedSearchQuery={debouncedSearchQuery}
                                            setSearchQuery={setSearchQuery}
                                            searchSubmit={searchSubmit}
                                            isMobileSearchPortal
                                        />
                                    </div>
                                </div>
                            </div>

                            <SearchSuggestion
                                keywords={keywords}
                                suggestedProducts={productSuggestions}
                                searchResultsCount={searchResultsCount}
                                searchQuery={searchQuery}
                                goToSearchResultsPage={goToSearchResultsPage}
                                landingPage={landingPage}
                                searchResponse={searchResponse}
                                handleProductClick={handleProductClick}
                                searchSuggestionRef={searchSuggestionRef}
                            />
                        </div>,
                        document.getElementById('search-overlay-portal')!
                    )}
                </>
            ) : (
                SKIP_RENDER
            )}
        </div>
    );
}

export default injectComponent('pattern.organism.SearchBar', SearchBar);
