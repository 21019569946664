import { useMutation, useQueryClient } from '@tanstack/react-query';
import noop from 'lodash/noop';
import analytics from '../../analytics';
import { ApiError } from '../../api/ApiError';
import { Cart } from '../../api/types/ClientCart';
import { QueryKeys } from '../../constants/queryKeys';
import { useAppDispatch } from '../../store/hooks/hooks';
import { cartReceived, installmentPlanReset, loadingCartStarted } from '../../store/reducer/cart';
import { useCartApiClient } from '../../api/hooks/useCartApiClient';

type UseAddDiscountCodeProps = {
    onError?: (error: ApiError) => void;
    onSuccess?: () => void;
};

interface MutationProps {
    discountCode: string;
    partnerId?: string;
}

/**
 * Hook for adding discount code to cart
 * @param props callbacks
 * @returns useMutation result
 */
export const useAddDiscountCode = ({ onError = noop, onSuccess = noop }: UseAddDiscountCodeProps = {}) => {
    const apiClient = useCartApiClient();
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();

    const addDiscountCode = ({ discountCode, partnerId }: MutationProps) => {
        dispatch(loadingCartStarted());
        return apiClient.addDiscountCode(discountCode, partnerId);
    };

    const onErrorInternal = (error: ApiError, { discountCode }: MutationProps) => {
        analytics.dispatchDiscountApplied({
            couponSuccess: false,
            coupon: discountCode,
        });

        onError(error);
    };

    const onSuccessInternal = (cart: Cart) => {
        dispatch(installmentPlanReset());

        dispatch(cartReceived({ cart }));
        queryClient.setQueryData([QueryKeys.FETCH_CART], cart);

        analytics.dispatchDiscountApplied({
            couponSuccess: true,
            coupons: cart.discountCodes?.map((code) => code.discountCode?.code ?? 'DC_DELETED'),
        });

        onSuccess();
    };

    return useMutation({
        mutationFn: addDiscountCode,
        onError: onErrorInternal,
        onSuccess: onSuccessInternal,
    });
};
