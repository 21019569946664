/* eslint-disable id-length */
export default {
    test: 'Hallo Test auf Deutsch',
    account: {
        test: 'Hallo {name} auf Deutsch im Account',
        subscribeButton: 'hírlevél regisztráció',
        unSubscribeButton: 'leíratkozás a hírlevélről',
        unsubscribeSuccess: 'Ön sikeresen leiratkozott a hírlevélről.',
    },
    deal: {
        days: 'N',
        hours: 'Ó',
        minutes: 'P',
        seconds: 'MP',
        dealOver: 'Az ajánlat lejárt.',
        countdownEnd: 'Ez az ajánlat véget ér:',
    },
    mainNavigation: {
        greeting: 'Üdvözöljök, {name}',
        account: 'Az én fiókom',
        logout: 'Kijelentkezés',
        search: 'Termékkereső...',
        loggedout: 'Ön kijelentkezett!',
        searchSuggestion: 'Keresési javaslatok',
        productSuggestion: 'Termékjavaslatok',
        showResults: '{count, plural, =1 {{text} találat megjelenítése} other {Minden {text} találat megjelenítése}}',
        resultSearch: '{count} találat a következőre: {query}',
        logoLinkLabel: 'Kezdőlap',
        accountLinkLabel: 'Vásárlói fiók',
        content: 'Tartalom',
        burgerMenuButtonText: 'Menü',
    },
    product: {
        cheaperSet: '2. ára csak ',
        setTypeXplusY: '2. és minden további ára csak ',
        quantityAdvantage: 'Mennyiségi kedvezmény: ',
        quantityAdvantageActive: 'Mennyiségi kedvezmény!',
        ratingCount: '{count} {count, plural, one {értékelés} other {értékelések}}',
        unitPriceSuffix: '\u202f/\u202f{baseUnitMultiplier}\u00a0{referenceUnit}',
        priceHint: 'Az ár tartalmazza az ÁFA-t.',
        deliveryHint: 'Szállítási és fizetési információk',
        savedMoneySuffix: ' Megtakarítás',
        savedMoneyBadge: 'megtakarítás',
        buyNow: 'Megrendelem most',
        addToCart: 'Kosárba teszem',
        goToPdp: 'tovább a termékleíráshoz',
        showDetails: 'Részletek',
        toProduct: 'A termékhez',
        descriptionShowMore: 'még több...',
        descriptionShowLess: 'még kevesebb...',
        detailsShowMore: 'még több részlet',
        detailsShowLess: 'kevesebb részlet',
        variantSelectorTitle: 'Kérjük, válasszon:',
        emptyVariant: 'Kérjük, válasszon',
        chooseVariant: 'Kérjük, válasszon',
        emptyVariantError: 'Kérjük, válasszon',
        suggestedPriceShort: 'UVP',
        uvp: 'UVP',
        statt: 'helyett',
        variantSize: 'méret',
        variantColor: 'szín',
        badges: {
            installments: '{count}x {count, plural, one {részlet} other {részletek}} {price}',
            installmentsVariants: 'egyenként {count}x {count, plural, one {részlet} other {részletek}} {price}',
            installmentsVariantsAsAlternative:
                'vagy egyenként {count}x {count, plural, one {részlet} other {részletek}} {price}',
            installmentsAsAlternative: 'vagy {count}x {count, plural, one {részlet} other {részletek}} {price}',
            installmentsInFinalSummary: '{count}x {count, plural, one {részlet} other {részletek}}',
            installmentsInCart: '{count}x {count, plural, one {részlet} other {részletek}} {price}',
            xplusygratis: '{text}',
            savings: '{count}% kedvezmény',
            savingsPDP: '-{count}%',
            deal: 'AKCIÓ -{count}%',
            mobile: {
                gratisZubehoer: '+kiegészítők',
            },
        },
        socialMediaShareHeadline: '{shopName}',
        socialMediaShareText: 'Nézd, mit találtam',
        attributes: {
            headline: 'Termékinformációk',
            subheadline: 'Technikai adatok',
            true: 'Igen',
            false: 'Nem',
            maxOrderQuantity: 'max. rendelhető mennyiség',
            deliverables: 'szolgáltatások',
            name: 'Name',
            description: 'leírás',
            usp: 'USP',
            productDetails: 'Termékrészletek',
            claim: 'Claim',
            weightGross: 'Súly',
            articleType: 'Terméktípus',
            articleIsBuyable: 'Rendelhető',
            erpCategory1: 'Kategória',
            erpCategory2: 'Alkategória',
            crmArticleBundleNo: 'Artikelbündel Nr.',
            webSubtitle: 'Web alcím',
            countryLicensesAvailable: 'Ország elérhetőség',
            articleDimensions: 'Dimenziók',
            textilMaterialComposition: 'Anyag',
            relatedUpsell: 'Upsell',
            relatedUpgrade: 'Tartozék',
            subscriptionAvailable: 'Előfizetés',
            subscriptionSelection: {
                day: '<b>Szállítási idő</b>: {amount} {amount, plural, one {Nap} other {Nap}}',
                month: '<b>Szállítási idő</b>: {amount} {amount, plural, one {Hónap} other {Hónap}}',
                week: '<b>Szállítási idő</b>: {amount} {amount, plural, one {Hét} other {Hét}}',
            },
            deliveryTimeFrom: 'min. szállítási idő',
            deliveryTimeUntil: 'max. szállítási idő',
            medicalProduct: 'Egészségügyi termék',
            productChemicalIngredients: 'Összetevők',
            signalWord: 'Signalwort',
            ean: 'EAN',
            eanNl: 'EAN NL',
            prices: 'Árak',
            biocidalProduct: 'Biocid termék',
            shippingTimeCode: 'szállítási idő kód',
            expiryDate: 'lejárati idő',
            navBulkyItem: 'Nav Bulky Item',
            perDelivery: 'szállításonként',
        },
        shipping: {
            dropShipment: 'plusz szállítási díj (Spedition): {price}',
            bulkyDelivery: 'plusz Szállítási díj (Sperrgut): {price}',
            totalCost: 'Végösszeg: {price}',
        },
        addedToCart: 'Remek választás! A termékek hozzáadtuk!',
        cantAddToCart: 'A terméket nem került a kosárba',
        productNumber: 'cikkszám',
        productRelation: 'Ez is érdekelheti',
        payOnce: 'vagy egyszer',
        payOnceSum: 'végösszeg',
        payOnceSumMobile: 'Összeg',
        next: 'következő',
        previous: 'előző',
        deliveryProductCard: 'Szállítási idő: {from}-{until} munkanap',
        downloadFor: 'Letölthető kézikönyv',
        topReview: 'TOP értékelés',
        seeMore: 'Még több...',
        seeLess: 'Kevesebb...',
        totalReviews: '{formattedCount} {count, plural, one {értékelés} other {értékelések}}',
        noReviews: 'Nincs megjeleníthető elem',
        videoThumbnailText: 'Videó',
        subscription: {
            oneTimeDelivery: 'Egyszeri szállítás',
            abo: 'Takarékos előfizetés',
            emptyOption: 'Szállítási gyakoriság:',
            intervalUnit: {
                day: '{amount} {amount, plural, one {Nap} other {Nap}}',
                month: '{amount} {amount, plural, one {Hónap} other {Hónap}}',
                year: '{amount} {amount, plural, one {Év} other {Év}}',
            },
            delivery: 'szállítás',
            recommendedInterval: 'ajánlott',
        },
        exceededMaxOrderQuantity: 'A maximális rendelési mennyiség ({amount}) ennél a tételnél túllépésre került.',
        pageTitle: '{title}{store, select, EMPTY {} other { | {store}}}',
        productUnavailable: {
            headline: 'A termék jelenleg nem elérhető!',
            text: 'Kérjük válasszon más terméket a kínálatból!',
        },
        bundleTitle: 'Készletként is elérhető:',
    },
    preCheckout: {
        successMessage: 'Remek választás! A terméket hozzáadtuk!',
        ctaMessage: 'kosárba teszem és',
        ctaMessageBold: 'szállítási díj nélkül rendelek!',
        goBackBtn: 'Vásárlás folytatása',
        goToCartBtn: 'Ugrás a kosárhoz',
        showDetails: 'Részletek',
        addToCart: 'Kosárba teszem',
        addToCartUpgrade: 'Frissítés',
        relations: 'Hozzárendelem',
        toProduct: 'a termékhez',
        deliverables: 'Csomagtartalom: ',
        clubSuggestion: 'További megtakarítás?',
        clubCardAlreadyInCart: 'Önnek már van egy Törzsvásárlói kártyája a kosarában.',
    },
    orderSuccessPage: {
        orderSuccess: 'Köszönjük megrendelését!!',
        goToShop: 'Vissza a webshopba!',
        accountQuestion: `Szeretne elmenteni egy jelszót, hogy később a regisztráció minden előnyét élvezhesse?`,
        accountAdvantagesHeader: 'Ezért előnyös Önnek:',
        accountAdvantageOne: 'Az összes megrendelés áttekintése',
        accountAdvantageTwo: 'Gyorsabb rendelésvégrehajtás',
        accountAdvantageThree: 'Ügyféladatok kezelése',
        password: 'jelszó',
        passwordReq: 'min. 8 karakter',
        passwordReqDescription: 'Adjon meg egy jelszót!',
        passwordReqDescTitle: 'A jelszava tartalmazzon:',
        passwordReqDescOne: 'legalább 8 karaktert',
        passwordReqDescTwo: 'tartalmazzon legalább 1 számot',
        passwordReqDescThree: 'legalább 1 nagybetűt',
        passwordReqDescFour: 'legalább 1 kisbetűt',
        checkboxText: 'Szeretnék értesülni a legújabb kedvezményekről és kuponokról',
        checkboxInfo: 'A leiratkozás bármikor díjmentesen visszavonható',
        passwordSaveBtn: 'Save',
    },
    cart: {
        noReservation: 'A kosárban lévő termékek nincsenek lefoglalva',
        headline: 'Kosár',
        articlesWithCount: '{count} termék',
        addons: {
            headline: 'Megrendelem hozzá',
            add: 'Hozzárendelem',
        },
        subtotal: 'Részösszeg',
        deliveryCost: 'Csomagolás és szállítás',
        total: 'Teljes összeg',
        allTotal: 'Végösszeg',
        shippedFreeOne: 'Ez a rendelés',
        shippedFreeTwo: 'INGYEN SZÁLLÍTÁSSAL ',
        shippedFreeThree: ' kerül kiküldésre!',
        vatIncluded: 'Tartalmazza az ÁFA-t.',
        free: 'INGYENES',
        savings: 'Teljes megtakarítás',
        savingsText: 'Ön összesen ennyit spórol:',
        toCheckout: 'A kasszához',
        goSafeToCheckout: 'IRÁNY A KASSZA',
        xArticle: '({amount} termék)',
        voucher: 'Kupon',
        redeemVoucher: 'Kuponkód megadása',
        codePlaceholder: 'Kuponkód (opcionális)',
        redeemCTA: 'Beváltom',
        voucherCodeSuccess: 'Gratulálunk, kuponkódja"{code}" beváltásra került',
        voucherCodeErrorCodes: {
            alreadyPresent: 'Kuponkód "{code}" már beváltva',
            nonApplicable: 'A kupon (már) nem elérhető, vagy nem használható fel a jelenlegi kosártartalomnál.',
        },
        emptyCart: 'A kosár üres',
        emptyCartMessage: 'Válassza ki kedvenc termékeit és helyezze őket a kosárba',
        emptyCartButton: 'Vissza a webshopba',
        emptyCartButtonMessage: 'Folytatom a vásárlást',
        voucherModal: {
            headline: 'Az utalványt sikeresen aktiváltuk!',
            headlineGiftLineItem: 'Az ingyenes ajándékodat aktiváltuk!',
            text: '{totalSavings} megtakarítás {minValue} minimális rendelési értéktől',
            continue: 'Irány a webshop',
        },
        wkoProductTitle: 'Az Ön ajándéka',
        wkoModalHeadline: 'Rendelje meg egyszerre a kiegészítőket és megajándékozzuk',
        wkoDeclineOffer: 'Köszönöm, nem',
        wkoAddToCartSuccess: 'Jó döntés!',
        restoreCartError: 'A kosarát nem sikerült helyreállítani.',
        clubSavings: {
            clubSavingsText: 'Spóroljon további {savings}-ot a Törzsvásárlói klubbal! ',
            clubSavingsLink: 'Kattintson {clubSavingsUnderline} a részletekért!',
            clubSavingsUnderline: 'ide',
        },
        clubExtensionCta: 'Kiterjeszteni',
        freeShippingBanner: {
            freeShipping: 'ingyenes szállításért!',
            headline: 'Vásároljon még további {onlyLeftPrice} értékben az {freeShipping} (összeghatár: {fromPrice})',
            continue: 'Tovább ',
            shopping: 'vásárolok',
            order: 'Ezt a rendelést ',
            freeDelivery: 'postaköltség nélkül szállítjuk!',
        },
    },
    category: {
        showMore: 'Mutass többet',
        breadcrumbHomeAriaLabel: 'Honlap',
    },
    voucher: {
        codes: {
            notActive: 'Ez a kupon nem aktív.',
            notValid: 'Ez a kupon már nem érvényes',
            doesNotMatchCart: 'Ennek a kuponnak a beváltási feltételei nem teljesülnek.',
            maxApplicationReached: 'Ezt a kupont már beváltották',
            applicationStoppedByPreviousDiscount: 'Ez a kupon egy másik beváltása miatt nem érvényesíthető',
            matchesCart: 'Az utalvány érvényes',
        },
        modal: {
            dontShowLegalText: 'Az utalvány feltételeinek elrejtése',
            displayLegalText: 'Az utalvány feltételeinek megjelenítése',
            savings: '{amount} megtakarítás',
            headline: 'Melyik utalványt szeretné megtartani?',
            minimalOrderAmount: ' {amount} minimális rendelési érték felett',
        },
        priceGiftLineItem: 'Ingyenes ajándék: -{price}',
    },
    checkout: {
        minicartEdit: 'szerkesztés',
        dividerText: 'vagy',
        allowRecurrence: {
            checkbox: 'ismételt fizetések engedélyezése',
            error: 'Engedélyeznie kell az ismételt fizetéseket.',
        },
        catalogBillingAddressHeadline: 'Számlázási cím',
        editBillingAddress: 'Számlázási cím szerkesztése',
        billingAddressHeadline: '1. Számlázási cím',
        guestInfo:
            'Az Ön adatait csak ehhez a megrendeléshez gyűjtjük, ' +
            'a további megrendelésekhez újra meg kell adnia azokat. ' +
            'Ha szeretné kihasználni a vásárlói fiók előnyeit, ' +
            'lehetősége van ingyenesen létrehozni a megrendelés ' +
            'befejezése után.',
        paymentHeadline: 'Fizetési mód kiválasztása',
        summaryHeadline: 'Összefoglalás',
        nextStep: 'Tovább',
        checkOrder: 'Rendelésellenőrzés',
        nextStepPayment: 'Tovább a fizetési módokhoz',
        lastStep: 'Egy lépést vissza',
        buyNow: 'Megrendelem most',
        cart: 'Kosár',
        lineItem: {
            quantity: 'Mennyiség',
        },
        freeDelivery: 'Jó hírünk van! Ez a rendelés ingyen szállítással kerül postázásra!',
        enterAddress:
            'Kérjük, először adja meg címadataiat, ' +
            'majd a TOVÁBB gombra kattintva ' +
            'kiválaszthatja a fizetési módot',
        addresses: {
            headline: 'Adatok',
            customerLogin: 'Bejelentkezés',
            guestOrder: 'Regisztráció nélkül rendelek',
            register: 'Ügyfélfiók létrehozása',
        },
        payment: {
            paymentFee: 'Tranzakciós költség: {price}',
            paymentFeeForNachnahme: 'Utánvét díj: {price}',
            noPaymentFee: 'nincs kezelési költség',
            noPaymentFeeForNachnahme: 'Utánvét díj: 0 Ft',
            networkError:
                'Sajnos jelenleg nem áll módunkban előre utalásos ' +
                'vásárlást kínálni. Kérjük, válasszon más fizetési módot!',
            tryOtherNetworkError:
                'Sajnos jelenleg nem áll módunkban előre utalásos vásárlást ' +
                'kínálni. Kérjük, válasszon más fizetési módot!',
            installmentNetworkError:
                'Sajnos erre a megrendelésre nem tudunk részletfizetést ajánlani. ' +
                'Kérjük, válasszon egy másik fizetési módot a megrendelés befejezéséhez.',
            invoiceNetworkError:
                'Sajnos jelenleg nem áll módunkban előre utalásos ' +
                'vásárlást kínálni. Kérjük, válasszon más fizetési módot!',
            invoiceCheckDateOfBirth:
                'Az Ön által megadott adatokat nem sikerült ellenőrizni. ' +
                'Kérjük, ellenőrizze, hogy a megadott születési dátum helyes és teljes.',
            invoiceAddressError:
                'Felhívjuk figyelmét, hogy a szállítási és a számlázási címnek meg kell ' +
                'egyeznie a számlavásárláskor az azonosítás érdekében. ' +
                'Az Ön jelenlegi címadatai nem felelnek meg ennek a követelménynek, ' +
                'ezért a számlás vásárlás nem lehetséges. ' +
                'Kérjük, válasszon egy másik fizetési módot a megrendelés befejezéséhez.',
            genericError:
                'A fizetés sikertelen. Mielőtt újrapróbálkozna, ellenőrizze számlázási és fizetési adatait, ' +
                'vagy használjon másik fizetési módot.',
            unavailable: 'Ez a fizetési mód pillanatnyilag nem választható.',
            invoice: 'Kauf auf Rechnung',
            invoiceoffline: 'Kauf auf Rechnung',
            collectminvoice: 'Kauf auf Rechnung',
            mastercard: 'Mastercard',
            paypal: 'PayPal',
            paypalexpress: 'PayPal Express',
            postfinancecard: 'PostFinance',
            visa: 'VISA',
            sofortueberweisung: 'Sofort-Überweisung',
            nachnahme: 'Utánvét',
            installment: 'Részletfizetés',
            installmentActivateCTA: 'Ellenőrzés',
            installmentCheckbox:
                'Sie stimmen der Ratenzahlungsvereinbarung zu und können den ' +
                'Ratenkredit-Vertragsentwurf {linkStart}hier{linkEnd} herunterladen.',
            afterpayinvoice: 'AfterPay',
            idealr: 'iDEAL',
            mistercashR: 'Bancontact',
            unavailableShippingCountryTitle: 'Megjegyzés',
            unavailableShippingCountry: 'A rendelés az Ön által kiválasztott országba nem szállítható.',
            descriptions: {
                visa: 'Biztonságos fizetés VISA Secure segítségével.',
                mastercard: 'Biztonságos fizetés MasterCard Identity Check segítségével.',
                payPal:
                    'Egyszerű fizetés a PayPal-fiókon keresztül. A rendelési ' +
                    'folyamat végén közvetlenül a PayPal-hoz irányítjuk át. ' +
                    'A megrendelése csak a fizetés feldolgozása után válik teljessé.',
                postFinanceCard:
                    'Fizessen egyszerűen és kényelmesen postai számlájának megterhelésével. A vásárlás megerősítése után adja meg a PostFinance kártya és a PostFinance kártya azonosító számát a PostFinance fizetési képernyőn, és erősítse meg a fizetést.',
                payPalPayLater:
                    '(Ha szeretné, a PayPal rendszerbe való bejelentkezés után kiválaszthatja a „Fizetés 30 nap után” opciót is).',
                sofortueberweisung:
                    'Fizetés az online banki adatokkal. A vásárlás megerősítése után Ön közvetlenül a Sofort Klarna biztonságos fizetési portáljára kerül.',
                invoice:
                    'Vásároljon egyszerűen számlával. A számlát a szállítással együtt kapja meg. Kérjük, vegye figyelembe, hogy a szállítási címnek és a számla címének meg kell egyeznie a számlás vásárláskor történő azonosítás érdekében. Ez a fizetési mód 18 éves minimum életkort igényel, és hitelképesség-ellenőrzést tartalmaz. Győződjön meg róla, hogy helyesen adja meg a születési dátumát.',
                invoiceOffline: 'Vásároljon egyszerűen számlával. A számlát a szállítással együtt kapja meg',
                installment:
                    'Fizessen biztonságosan és rugalmasan részletekben. Ez a fizetési mód 18 éves korhatárt igényel, és hitelképesség-ellenőrzést tartalmaz. Győződjön meg róla, hogy helyesen adta meg címadatait és születési dátumát',
                ideal: 'Fizetés online banki adataival. A vásárlás megerősítése után közvetlenül holland bankja online portáljára kerül, ahol online banki bejelentkezési adataival bejelentkezhet a fizetés lebonyolításához',
                afterPay:
                    'Számlával történő fizetés mindössze néhány lépésben. Ezt a fizetési módot az AfterPay kezeli, és legalább 18 éves kor szükséges hozzá. A születési dátum és a telefonszám helyes megadása kötelező, hogy az AfterPay elvégezhesse a személyazonosság és a hitelképesség ellenőrzését',
                bancontact:
                    'Szüksége van egy Bancontact kártyára egy részt vevő banktól. A vásárlás megerősítése után közvetlenül a Bancontact online portálra kerül, ahol vagy a Bancontact alkalmazást használja, vagy manuálisan megadja a Bancontact kártya adatait a fizetés befejezéséhez',
            },
        },
        shippingAddressHeadline: 'Szállítási cím',
        summary: {
            headline: 'Összefoglalás',
            usps: {
                moneyBack: 'Pénz-visszafizetési garancia',
                freeDelivery: 'ingyenes szállítás',
                refund: '14 napos pénz-visszafizetési garancia',
                freeReturn: 'díjmentes visszaküldés',
                securePayment: 'biztonságos fizetés',
            },
            subtotal: 'Részösszeg',
            deliveryCost: 'Szállítási díj',
            dropShippingCost: 'Szállítási díj (Spedition)',
            bulkyDeliveryCost: 'Szállítási díj (Sperrgut)',
            transactionFee: 'Tranzakciós költség',
            transactionFeeCashOnDelivery: 'Utánvét díj',
            total: 'Végösszeg',
            taxIncluded: 'ÁFA-t tartalmaz',
            totalSavings: 'Teljes megtakarítás',
            privacy: 'Az adatait kizárólag az {termsDetails} megfelelően használjuk.',
            legal: 'Az {termsDetails} elfogadásával és beleegyezésével a "Megrendelés" gombra kattintva véglegesítheti rendelését.',
            legalDetails: {
                privacyText: 'Adavédelmi Szabályzatnak',
                privacyLink: '/adatvedelem',
                termsText: 'Általános Szerződési Feltételek',
                termsLink: '/aszf',
            },
            permissionEmailPhonePost:
                'Hozzájárulok, hogy Hozzájárulok, hogy az itt megadott email-címemet,' +
                'telefonszámomat és postai címadataimat a' +
                'Mediashop - Telemarketing Internaional Kft. a velem való kapcsolatfelvétel, kapcsolattartás céljából' +
                'rögzítse rendszerében és jelen hozzájárulásom visszavonásáig kezelje.',
            permissionEmailPhonePostDetails: {
                link: '/adatvedelem',
            },
            buyNow: 'Megrendelem most',
            deliveryTime: '{time} munkanapon belül szállítható',
            newsletterSubscription:
                'Szeretném megkapni a {shopName} ingyenes ' +
                'hírlevelét ajánlatokkal, kedvezményekkel és kuponokkal e-mailben. ' +
                'A hírlevélben található linkre kattintva bármikor leiratkozhat.',
            comment: 'Üzenet a kézbesítőnek',
        },
        orderNotSavedYet: 'Megrendelése még nem került mentésre. Biztos, hogy el akarja hagyni az oldalt?',
        resetPassword: {
            title: 'Megjegyzés',
            textBold: 'Ön régi vásárlónk, és a korábbi webshop regisztrált ügyfele?',
            textNormal: 'Használja korábbi ügyfél-adatlapját',
            textCta: 'Eredeti jelszó visszaállítás',
            resetButton: 'Jelszó visszaállítás',
            closeButton: 'Bezár',
        },
        multiPage: {
            goToCartModal: {
                headline: 'Figyelem',
                description: 'Biztosan vissza akar térni a kosarához?',
                dismiss: 'Maradjon a fizetési folyamatban',
                submit: 'Kosár szerkesztése',
            },
            summary: {
                amount: 'Mennyiség: {amount}',
                billingAddress: 'Számlázási cím',
                billingAndDelivery: 'Számlázás és szállítás',
                change: 'megváltoztat',
                lineItemTotalPrice: 'Végösszeg: {price}',
                paymentType: 'Fizetési mód',
                shippingAddress: 'Szállítási cím',
                title: 'Összesítő',
                yourOrder: 'Az Ön megrendelése',
            },
            headlines: {
                headline: 'Biztonságos rendelés 3 lépésben',
                address: 'Cím',
                payment: 'Fizetési mód',
                summary: 'Ellenőrzés és küldés',
            },
            nextButton: 'Tovább',
            checkDataButton: 'Adatok ellenőrzése',
        },
        customerClubExtensionModal: {
            title: 'Figyelem',
            text:
                'Ön már tagja az ügyfélklubnak. A hűségkártyát eltávolítottuk a kosarából. ' +
                'Szeretné helyette meglévő tagságának megújítását megvásárolni?',
            addToCart: 'Megújítás vásárlása',
            closeButton: 'Bezárás',
        },
        subscriptionInfo:
            'Az előfizetés megvásárlásához ügyfélfiókra van szükség. Jelentkezzen be meglévő ügyfélfiókjával, vagy ' +
            'egyszerűen hozzon létre egy új ügyfélfiókot.',
    },
    totalPrice: {
        taxIncluded: 'ÁFÁ-t tartalmaz',
    },
    address: {
        sectorPlaceholder: 'Sec',
        blockPlaceholder: 'Bl',
        stairwayPlaceholder: 'Sc',
        floorPlaceholder: 'Et',
        apartmentPlaceholder: 'Ap',
        billingAddress: 'Számlázási cím*',
        requiredField: '* kötelezően kitöltendő mező',
        emailLabel: 'email cím',
        emailPlaceholder: 'email cím',
        emailErrorText: 'Kérjük, valós email címet adjon meg.',
        salutationLabel: 'Üdvözlés*',
        salutationPlaceholder: 'Megszólítás',
        salutations: {
            ms: 'Hölgy',
            mr: 'Úr',
            none: 'nem adom meg',
        },
        customerTypeLabel: 'Vásárlás típusa',
        customerType: {
            private: 'Magánszemély',
            corporate: 'Cég',
        },
        contactLabel: 'Elérhetőségek*',
        contactPerson: 'Kapcsolattartó',
        contactInfo: 'Elérhetőségek',
        firstnamePlaceholder: 'Keresztnév',
        firstnameErrorText: 'Kérjük, valós adatot adjon meg!',
        lastnamePlaceholder: 'Vezetéknév',
        lastnameErrorText: 'Kérjük, valós adatot adjon meg!',
        nameLengthErrorText: 'Maximum 200 karakter megengedett.',
        phonePlaceholder: 'Telefonszám',
        phoneErrorText: 'Kérjük, valós telefonszámot adjon meg',
        company: 'Cégnév',
        companyPlaceholder: 'Cégnév',
        companyErrorText: 'Kérjük, valós cégnevet adjon meg!',
        vatIdPlaceholder: 'Adószám',
        vatIdErrorText: 'Kérjük, valós adatot adjon meg!',
        address: 'Cím',
        streetPlaceholder: 'Közterület neve',
        streetErrorText: 'Kérjük, valós adatot adjon meg!',
        streetLengthErrorText: 'Maximum 100 karakter megengedett.',
        streetLengthErrorTextCZ: 'Maximum 40 karakter megengedett.',
        streetNumberPlaceholder: 'Házszám',
        streetNumberErrorText: 'Kérjük, valós adatot adjon meg!',
        zipCodePlaceholder: 'Irányítószám',
        zipCodeErrorText: 'Kérjük, valós adatot adjon meg!',
        cityPlaceholder: 'Település',
        cityErrorText: 'Kérjük, valós adatot adjon meg!',
        country: 'Ország',
        countryLabel: 'Ország',
        countryPlaceholder: 'Ország kiválasztása',
        countyLabel: 'Kerület',
        countyPlaceholder: 'Kerület kiválasztása',
        packingStationPlaceholder: 'Csomagpontra szállítás?*',
        packingStation: {
            yes: 'Igen',
            no: 'Nem',
        },
        shippingAddressHeadline: 'Szállítási cím*',
        shippingAddressPlaceholder: 'Ez a szállítási cím is*',
        shippingAddress: {
            yes: 'Igen',
            no: 'Nem',
        },
        dayPlaceholder: 'Nap',
        monthPlaceholder: 'Hónap',
        yearPlaceholder: 'Év',
        birthdateTitle: 'Születési dátum',
        birthdatePlaceholder: '1990-01-23',
        birthdateErrorText: 'Kérjük, érvényes adatot adjon meg!',
        birthdateDayErrorText: '*érvénytelen adat',
        birthdateTooYoungErrorText: 'Csak 18 éven felülieknek.',
        birthdateMonthErrorText: '*érvénytelen adat',
        birthdateYearErrorText: '*érvénytelen adat',
        birthdayTitle: 'Születési dátum',
        birthDayError:
            'Online megrendelést csak 100 évnél fiatalabb ügyfeleinktől tudunk elfogadi. Megértését köszönjük!',
        loqatePlaceholder: 'Cím megadása',
        loqateManualAddress: 'Cím manuális megadása',
        loqateManualAddressHint: 'vagy egyszerűen cím kereséssel:',
        loqateSearchAddress: 'esetleg keresés cím alapján?',
        noResults: 'Sajnálom, nem volt eredmény',
    },
    checkoutSuccess: {
        headline: 'Köszönjük megrendelését!',
        headlineError: 'Elnézést kérünk, hiba történt. Kérjük, próbálja újra, vagy fordulon ügyfélszolgálatunkhoz!',
        textFirst:
            'Megrendelését megkaptuk, melyről az alábbi címre egy visszaigazoló emailt küldtünk.' +
            'Kérjük, nézze meg a SPAM-mappát is. ' +
            '<link>{email}</link>.',
        textSecond: 'Amint megrendelése elhagyja raktárunkat, emailben értesítjük Önt!',
        textSecondPending: 'Megrendelése feldolgozás alatt van Emailben értesítjük, ha a feldolgozás befejeződött.',
        textError: 'Rendelése állapotáról kérjük, érdeklődjön ügyfélszolgálatunknál.',
        noEmailPlaceholder: 'Az Ön email-címe',
        newsletter: {
            headline: 'Newsletter',
            text: 'Szeretnék ingyenesen értesülni a {shopName} aktuális ajánlatairól hírlevél formájában.',
            feedbackText:
                'Hírlevél feliratkozás sikeres! Rövidesen kap egy e-mailt, amelyben egy linket küldünk ' +
                'az e-mail címe megerősítéséhez.',
            error: 'A hírlevélre való feliratkozás jelenleg nem lehetséges.',
        },
        catalog: {
            headline: 'Katalógus',
            text: 'Szeretném a {shopName} katalógust ingyenesen és kényelmesen, postai úton házhoz szállítva megkapni.',
            feedbackText: 'A katalógust hamarosan elküldjük az Ön címére.',
            error: 'A katalógus rendelés jelenleg nem lehetséges.',
        },
    },
    county: {
        alba: 'Alba',
        arad: 'Arad',
        arges: 'Arges',
        bacau: 'Bacau',
        bihor: 'Bihor',
        bistritaNasaud: 'Bistrita Nasaud',
        botosani: 'Botosani',
        braila: 'Braila',
        brasov: 'Brasov',
        bucuresti: 'Bucuresti',
        buzau: 'Buzau',
        calarasi: 'Calarasi',
        carasSeverin: 'Caras Severin',
        cluj: 'Cluj',
        constanta: 'Constanta',
        covasna: 'Covasna',
        dambovita: 'Dambovita',
        dolj: 'Dolj',
        galati: 'Galati',
        giurgiu: 'Giurgiu',
        gorj: 'Gorj',
        harghita: 'Harghita',
        hunedoara: 'Hunedoara',
        ialomita: 'Ialomita',
        iasi: 'Iasi',
        ilfov: 'Ilfov',
        maramures: 'Maramures',
        mehedinti: 'Mehedinti',
        mures: 'Mures',
        neamt: 'Neamt',
        olt: 'Olt',
        prahova: 'Prahova',
        salaj: 'Salaj',
        satuMare: 'Satu Mare',
        sibiu: 'Sibiu',
        suceava: 'Suceava',
        teleorman: 'Teleorman',
        timis: 'Timis',
        tulcea: 'Tulcea',
        valcea: 'Valcea',
        vaslui: 'Vaslui',
        vrancea: 'Vrancea',
    },
    country: {
        select: 'Országváltás',
        AT: 'Ausztria',
        DE: 'Németország',
        CH: 'Svájc',
        LI: 'Liechtenstein',
        HU: 'Magyarország',
        IT: 'Olaszország',
        BE: 'Belgium',
        NL: 'Hollandia',
        BG: 'Bulgária',
        HR: 'Horvátország',
        CY: 'Ciprus',
        CZ: 'Csehország',
        DK: 'Dánia',
        EE: 'Észtország',
        FI: 'Finnország',
        FR: 'Franciaország',
        GR: 'Görögország',
        IE: 'Íroroszág',
        LV: 'Lettország',
        LT: 'Litvánia',
        LU: 'Luxemburg',
        MK: 'Macedónia',
        MT: 'Málta',
        PL: 'Lengyelország',
        PT: 'Portgália',
        RO: 'Románia',
        SM: 'San Marino',
        SK: 'Szlovákia',
        SI: 'Szlovénia',
        ES: 'Spanyolország',
        SE: 'Svédország',
    },
    socialMedia: {
        facebook: 'Megosztom a Facebook-on',
        twitter: 'Megosztom a Twitter-en',
        whatsapp: 'Megosztom Whatsapp-on',
        native: 'Megosztás',
    },
    productListing: {
        noProducts: 'Nem találtunk termékeket',
        products: '{count} {count, plural, one {termék} other {termék}}',
        loadMore: 'mutass többet',
        from: 'von',
        articles: 'termék',
        title: '{categoryName} | {shopName}{count, plural, one {} other { - oldal {count}}}',
    },
    pagination: {
        ariaPage: '{pageNumber}. oldal',
        next: 'Tovább',
        previous: 'Vissza',
    },
    menu: {
        logout: 'Kijelentkezés',
        logoutHeader: 'Figyelem!',
        logoutText: 'Biztosan ki szeretne jelentkezni?',
        logoutButtonText: 'Kijelentkezés',
    },
    login: {
        password: 'Jelszó',
        submitButtonLabel: 'Biztonságos bejelentkezés',
        emailPlaceholder: 'Email cím',
        passwordLabel: 'Jelszó megadása',
        usernameEmptyError: 'A felhasználónév nem lehet üres',
        usernameEmailError: 'A felhasználónévnek valós emailcímnek kell lennie',
        userLoginError: 'A felhasználónév és/vagy email cím hibás',
        passwordForgotten: 'Elfelejtett jelszó?',
        loginSuccess: 'Ön be van jelentkezve',
        passwordEmptyError: 'A jelszó nem lehet üres',
        existingUser: 'Már regisztrált vásárló vagyok, tovább a belépésre',
        resetPassword: 'Jelszó visszaállítás',
        resetEmailSent: 'Elküldtük az emailt az alábbi címre: {email}.',
        resetErrorQuestions: 'Nem kapta meg a levelet?',
        resetErrorAnswerOne: 'Kérjük, ellenőrizze postafiókját, SPAM-mappáját.',
        resetErrorAnswerTwo: 'Ugyancsak ellenőrizze adatait.',
        resetErrorAnswerThree: 'Kérjük, várjon 15 percet, mielőtt újra megpróbálja.',
        newPassword: 'Új jelszó',
        passwordNotMatchingReq: 'A jelszó nem megfelelő',
        passwordsNotIdentical: 'Az új jelszavak nem egyeznek',
        repeatPassword: 'Jelszó megerősítés',
        changePassword: 'Jelszó megváltoztatása',
        passwordChanged: 'A jelszava megváltozott.',
        emailConfirmed: 'Az email címét sikeresen visszaiagzolta. Most már könnyen beléphet.',
        processToken: 'Hiba történt, kérjük, próbálja újra.',
        generalError: 'Hiba történt, kérjük, próbálja újra.',
        authorizeStoreTitle: 'Ügyfélfiók összekapcsolás',
        authorizeStoreText: 'Szeretné meglévő fiókját ehhez a webshophoz is  {shopName} felhasználni?',
        yes: 'Igen',
        no: 'Nem',
        emailUnconfirmed: 'Kérjük, erősítse meg emailcímét.',
    },
    orders: {
        order: 'Megrendelés',
        total: 'Végösszeg',
        details: 'Részletek',
        back: 'Vissza',
        shippingAddress: 'Szállítási cím',
        paymentMethod: 'Fizetési mód',
        paymentMethodNotApplicable: 'N/A',
        quantity: 'Mennyiség: {quantity}',
        reorder: 'Újrarendelem',
        reOrderSuccess: 'A terméket sikeresen hozzáadtuk',
        reOrderFail: 'A termék hozzáadása nem sikerült',
        date: 'Dátum',
        progress: '{total} rendelésből {items}',
    },
    customer: {
        mr: 'Úr',
        mrs: 'Hölgy',
        customerNumber: 'Ügyfélazonosító',
        billingAddress: 'Számlázási cím',
        email: 'Email-cím',
        phone: 'Telefonszám',
        password: 'Jelszó',
        passwordText: `Biztonsági okokból a jelszót nem jelenítjük meg'`,
        passwordLinkText: 'Elfelejtett jelszó?',
        tokenNotReceived:
            'Sajnos nem találjuk email-címét az adatbázisunkban. Kérjük, forduljon ügyfélszolgálatunkhoz.',
        emailInvalid: 'érvénytelen email-cím',
        dataUpdated: 'adatok frissítése',
        wrongPassword: 'A jelszót nem megfelelő',
        couldNotResetPassword: 'Jelszó visszaállítása nem lehetséges.',
        customerClub: {
            cardNumber: 'Kártya száma: {number}',
            validFrom: 'Érvényes a: {date}',
            validThru: 'Érvényes, amíg: {date}',
            expiryWarning: 'Figyelem: a kártya {days} napon belül érvényét veszti.',
            cardExpired: 'Figyelem: A kártyája lejárt.',
            offerCardRenewal: 'Új kártya rendelése',
        },
        customerCardLink: {
            headline: 'Kösse össze online fiókját és törzsvásárló kártyáját!',
            subHeadline: 'Használja ki klubtagsága előnyeit minden online vásárlásakor!',
            cardNumber: 'Kártyaszám',
            clubEmail: 'Törzsvásárlói email-cím',
            cta: 'Összekapcsolás',
            success: 'Ügyfélklubkártyáját sikeresen összekapcsoltuk a számlájával.',
            error: {
                mykeneNotFound: 'Nem találtunk a megadott számmal rendelkező ügyfélklubkártyát.',
                mykeneInvalidCombination: 'Az e-mail cím nem egyezik az ügyfélklubkártyával.',
                mykeneError: 'Hiba történt. Kérjük, próbálja meg később újra.',
                cardExistsForStore: 'Az Ön ügyfélklubkártyája már hozzá lett adva egy számlához.',
                unknownError: 'Hiba történt. Kérjük, próbálja meg később újra.',
            },
        },
    },
    register: {
        passwordEmptyError: 'A jelszó-mező nem lehet üres',
        passwordMinError: 'A jelszó legalább 8 karaktert tartalmazzon',
        passwordNotValidError: 'Legalább 1 speciális kataker, 1 kisbetű és 1 nagybetű a követelmény',
        passwordConfirmationError: 'A jelszavak nem egyeznek',
        passwordLabel: 'Jelszó létrehozása',
        passwordConfirmationLabel: 'Jelszó ismétlése',
        passwordCriteriaHeader: 'A biztonságos jelszó legyen ...',
        passwordCriteria: {
            numberOfCharacters: '✔ legalább 8 karakter hosszú',
            hasNumber: '✔ tartalmazzon legalább 1 számot',
            hasUppercaseLetter: '✔ tartalmazzon legalább 1 nagybetűt',
            hasLowercaseLetter: '✔ tartalmazzon legalább 1 kisbetűt',
        },
        acceptGDPRError: 'El kell fogadnia az adatvédelmi nyilatkozatot',
        nextStepPayment: 'Tovább a fizetéshez!',
        error: 'Sajnáljuk, hiba történt. Kérjük, próbálja újra!',
        successMessage: 'A regisztráció sikeres. Kérjük, most erősítse meg email-címét.',
        mainError: 'Kérjük, az alábbi hibákat javítsa ki',
        change: 'MÓDOSÍTÁS',
        accountVerificationTitle: 'Fiókellenörzés',
        accountVerificationText:
            'Rövidesen emailt küldünk Önnek, amiben' +
            'aktiválhatja fiókját! Figyelem, az aktiváló link 24 óráig érvényes. ' +
            'A 24 órán belül nem megerősített fiókok törlésre kerülnek.',
        accountVerificationCTA: 'Elfogadom',
        existingAccountModal: {
            text:
                'Ez az email-cím már használatban van. Amennyiben ezzel szeretne ehhez a webshophoz regisztrálni,' +
                ' kérjük jelentkezzen be a mentett adatokkal.',
            shops: `<ul><li>haus-hobby.ch</li><li>maison-hobby.ch</li><li>haus-hobby.com</li></ul>`,
            accept: 'Bejelentkezés',
            close: 'Bezárás',
        },
        newsletterSubscription: 'Szeretnék értesülni a {shopName} aktuális ajánlatairól.',
        existingAccountToast: 'Ez az email-cím már használatban van.',
    },
    countrySwitcher: {
        headline: 'Ország- és nyelv váltás',
        country: 'Ország',
        language: 'Nyelv',
        button: 'Mentés',
        modal: {
            headline: 'Figyelem!',
            description:
                'Ön éppen megváltoztatja a régió-beállításokat.' +
                '{newSelectedCountry}. Ahhoz, hogy rendelni tudjon, ' +
                'a szállítási címe az adott országban kell, hogy legyen.',
            submitText: 'Tovább',
            closeText: 'Mégsem',
        },
    },
    subcategoryFilter: {
        all: 'Mind',
        reset: 'szűrést kikapcsol',
    },
    personalInfo: {
        newEmail: 'Új email-cím',
        newEmailConfirm: 'Új email-cím megerősítése',
        passwordConfirm: 'Jelszó megerősítése',
        currentPassword: 'Jelenlegi jelszó',
        newPassword: 'Új jelszó',
        newPasswordConfirm: 'Új jelszó megerősítése',
        actualEmail: 'Jelenlegi email-cím',
    },
    newsletter: {
        inputPlaceholder: 'Email-cím',
        success: 'Elküldtük Önnek megerősítést kérő levelünket.',
        invalidEmail: 'Kérjük, valós email-címet adjon meg!',
        error: 'Sajnos a hírlevél regisztrációkor hiba lépett fel.',
        emailEmptyError: 'Kérjük, adja meg email-címét',
        registered: 'Sikeresen regisztrált hírlevelünkre.',
        notRegistered: 'Ön nem regisztrált hírlevelünkre',
        subscribe: 'Regisztrálás',
        unsubscribe: 'Lemondás',
        alreadySubscribed: 'Az Ön email-címét már regisztráltuk hírlevelünkre.',
        successAccount: 'Sikeresen regisztrált.',
        alreadyRegisteredQuestion: 'Ön már korábban regisztrált? ',
        unsubscribeHere: ' Itt tud leiratkozni',
        successUnsubscribe: 'Sikeresen leiratkozott',
        unsubscribeError: 'Hiba történt. Kérjük, próbálja meg később újra.',
    },
    cartChangeModal: {
        headline: 'Figyelem',
        description: 'A kosár tartalma frissült, kérjük, válassza ki újra a fizetési módot.',
        submitText: 'OK',
    },
    clubCustomer: {
        priceMessage: 'Törzsvásárlói ár: {price}',
        modal: {
            bannerHeadline: 'LEGYEN ÖN IS TÖRZSVÁSÁRLÓNK!',
            bannerSubHeadline: 'SZEMÉLYRE SZABOTT KEDVEZMÉNYEK ÉS KUPONOK VÁRJÁK ÖNT!',
            headline: 'MIÉRT ÉRI MEG A TÖRZSVÁSÁRLÓI TAGSÁG?',
            text:
                'Az éves Törzsvásárlói tagsággal több ezer Ft-ot spórolhat meg: ' +
                'keresse a Törzsvásárlói árakat termékeinknél! A tagság alatt ' +
                'garantáljuk az INGYENES SZÁLLÍTÁST is valamennyi megrendelésére. ' +
                'Számos egyéb különleges kedvezmény várja Klubunkban: *** most ' +
                'minden új regisztráció mellé 20.000 Ft értékű visszatérítést ' +
                'adunk kuponok formájában! *** A kuponokat a Kártyája mellé ' +
                'postázzuk, így már a következő megrendelésekor érvényesítheti ' +
                'azokat webáruházunkban, telefonos munkatársainknál, vagy ' +
                'akár személyesen mintaboltjainkban. A Törzsvásárlói kártya ' +
                'díja egy évre mindössze 9990 Ft. Figyelem: a Törzsvásárlói ' +
                'kártya regisztrációjával egyidejűleg a törzsvásárlói árakra + ' +
                'INGYEN SZÁLLÍTÁSRA is jogosult lesz, így azonnal több ezer Ft-ot ' +
                'megspórolhat! Érdemes tehát következő vásárlásakor megrendelni ' +
                'az éves tagságot jelentő kártyát is! Kattintson az alábbi ' +
                'gombra és rendelje meg saját kártyáját:',
            button: 'KLUBTAG SZERETNÉK LENNI',
        },
    },
    infoBanner: {
        closeButton: 'Bezár',
    },
    slider: {
        prevButton: 'Vissza',
        nextButton: 'Tovább',
        activePagination: 'aktív görgetés',
        inactivePagination: 'inaktív görgetés',
    },
    specialDeal: {
        days: '{count, plural, one {Nap} other {Napok}}',
        hours: 'Órá',
        minutes: 'Jeg.',
        seconds: 'Más.',
        specialDealOver: 'Sajnos ez az akció már nem elérhető.',
    },
    userManuals: {
        headline: 'Kézikönyvek',
        search: 'Keresés termék, cikkszám vagy kód szerint',
        resetSearch: 'Keresés visszaállítása',
        table: {
            sku: 'Cikkszám',
            code: 'Code',
            productTitle: 'Termék',
            type: 'Típus',
            types: {
                instruction_manual: 'Használati utasítás',
                recipe_booklet: 'Recept füzet',
                training_plan: 'Edzésterv',
                nutrition_plan: 'Táplálkozási terv',
            },
        },
        noResults: 'Nem talált kézikönyvek.',
    },
    price: {
        from: '{price}\u00a0-tól',
        reference: '{price}\u00a0/\u00a0{baseUnitMultiplier}\u00a0{referenceUnit}',
        statt: 'helyett\u00a0{price}',
        uvp: 'UVP\u00a0{price}',
    },
    emarsysFieldWriter: {
        success: 'A változtatás sikeresen megtörtént.',
        error: 'Hiba történt. Kérjük, próbálja meg később újra.',
    },
    subscription: {
        amount: 'Mennyiség:',
        deliveryInterval: 'Szállítási gyakoriság:',
        nextDelivery: 'Következő szállítás:',
        ordered: 'Elrendelte:',
        status: 'Állapot:',
        cancelSubscription: 'Feliratkozás visszavonása',
        state: {
            active: 'Aktív',
            paused: 'Szüneteltetve',
            cancelled: 'Kilépés',
            problems: 'Problémákat',
        },
        intervalUnit: {
            day: '{amount} {amount, plural, one {Nap} other {Nap}}',
            month: '{amount} {amount, plural, one {Hónap} other {Hónap}}',
            year: '{amount} {amount, plural, one {Év} other {Év}}',
        },
    },
    routerErrorBoundary: {
        title: 'Nem lehet csatlakozni a szerverhez',
        infoText: 'Kérjük, ellenőrizze internetkapcsolatát, vagy próbálja újra később.',
        buttonText: 'Oldal újratöltése',
        stacktraceTitle: 'Részletek',
    },
} satisfies IntlMessages;
