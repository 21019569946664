import { ProductWithActiveVariant } from '../../api/types/ClientProduct';
import { NeededPriceType } from '../../api/types/PriceType';
import { BrxRelationType } from '@mediashop/app/bloomreach/types';
import { DetailedProduct, DetailedVariant } from '../../api/types/ClientProductDetailed';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { QueryProductsResponse } from '../../api/types/ProductQueries';

export type ProductEntityState = 'unloaded' | 'loading' | 'succeeded' | 'error' | 'notfound';

export interface SlugProductEntity {
    product?: DetailedProduct;
    requestedPriceType?: NeededPriceType;
    selectedVariant?: DetailedVariant;
    isSelectedVariantSelectedByUser: boolean;
    slug?: string;
    status: ProductEntityState;
}

export interface ProductState {
    products: StoreProducts;
    slugProduct: SlugProductEntity;
    activeRelatedProduct?: {
        relatedProductSku: string;
        relationType?: BrxRelationType;
        validForSlug: string;
    };
    fallbackProductIds: string[];
}

export interface StoreProducts {
    [key: string]: StoreEntity;
}

export interface StoreEntity {
    status: ProductEntityState;
    product?: ProductWithActiveVariant;
}

const initialState: ProductState = {
    products: {},
    slugProduct: {
        status: 'unloaded',
        isSelectedVariantSelectedByUser: false,
    },
    activeRelatedProduct: undefined,
    fallbackProductIds: [],
};

const getLoadingProducts = function (ids: string[]): StoreProducts {
    const products = {};
    ids.forEach((id) => {
        products[id] = {
            status: 'loading',
        };
    });
    return products;
};

export const getSucceededProducts = (products: QueryProductsResponse): StoreProducts => {
    const entries = Object.entries(products.products);

    return entries.reduce((productData, [key, value]) => {
        productData[key] = {
            status: 'succeeded',
            product: value,
        };

        return productData;
    }, {});
};

const getInitialSelectedVariant = (variants: DetailedVariant[]) =>
    variants.filter((variant) => variant.price)[0] ?? variants[0];

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        fallbackProductIdsReceived(state, action: PayloadAction<{ productIds: Array<string> }>) {
            const { productIds } = action.payload;

            return {
                ...state,
                fallbackProductIds: productIds,
            };
        },
        productsReceived(state, action: PayloadAction<{ products: QueryProductsResponse }>) {
            const { products } = action.payload;

            /**
             * Reset slug in the state object
             * In case we're fetching products by slug, we need to reset the slug as an id in the state object
             */
            Object.values(getSucceededProducts(products)).forEach((product) => {
                if (product.product && state.products[product.product.slug]) {
                    if (product.product.slug && state.products) {
                        const name = Object.getOwnPropertyDescriptor(state.products, product.product.slug);
                        if (!name) {
                            return;
                        }
                        Object.defineProperty(state.products, product.product.id, name);
                    }
                    delete state.products[product.product?.slug];
                }
            });
            return {
                ...state,
                products: {
                    ...state.products,
                    ...getSucceededProducts(products),
                },
            };
        },
        loadingProductsStarted(state, action: PayloadAction<{ ids: Array<string> }>) {
            const { ids } = action.payload;
            return {
                ...state,
                products: {
                    ...state.products,
                    ...getLoadingProducts(ids),
                },
            };
        },
        loadingProductsFailed(state, action: PayloadAction<{ id: string }>) {
            const { id } = action.payload;
            return {
                ...state,
                products: {
                    ...state.products,
                    [id]: {
                        status: 'error',
                    },
                },
            };
        },
        productVariantSelected(state, action: PayloadAction<{ sku: string }>) {
            const { sku } = action.payload;
            const variant =
                state.slugProduct.product?.variants?.find((variant) => variant.sku === sku) ??
                state.slugProduct.product?.subscriptionProduct?.variants?.find((variant) => variant.sku === sku);

            return {
                ...state,
                slugProduct: {
                    ...state.slugProduct,
                    selectedVariant: variant,
                    isSelectedVariantSelectedByUser: true,
                },
            };
        },
        slugProductLoading(state, action: PayloadAction<{ slug: string }>) {
            const { slug } = action.payload;
            return {
                ...state,
                slugProduct: {
                    ...state.slugProduct,
                    slug,
                    status: 'loading',
                },
            };
        },
        slugProductReceived(
            state,
            action: PayloadAction<{ product: DetailedProduct; requestedPriceType?: NeededPriceType; slug?: string }>
        ) {
            const { product, requestedPriceType = 'STANDARD', slug } = action.payload;
            let currentProduct = product;
            if (product.subscriptionProduct && product.subscriptionProduct?.slug === slug) {
                currentProduct = product.subscriptionProduct;
            }
            return {
                ...state,
                slugProduct: {
                    isSelectedVariantSelectedByUser: false,
                    product,
                    selectedVariant: getInitialSelectedVariant(currentProduct.variants),
                    slug: product.slug,
                    status: 'succeeded',
                    requestedPriceType,
                },
            };
        },
        slugProductNotFound(state) {
            return {
                ...state,
                slugProduct: {
                    isSelectedVariantSelectedByUser: false,
                    slug: state.slugProduct.slug,
                    status: 'notfound',
                },
            };
        },
        slugProductRemoved(state) {
            return {
                ...state,
                slugProduct: {
                    isSelectedVariantSelectedByUser: false,
                    slug: state.slugProduct.slug,
                    status: 'unloaded',
                },
                activeRelatedProduct: undefined,
            };
        },
        variantProductRelationSelected(
            state,
            action: PayloadAction<{ sku: string; slug: string; relationType?: BrxRelationType }>
        ) {
            const { sku, slug, relationType } = action.payload;
            return {
                ...state,
                activeRelatedProduct: {
                    relatedProductSku: sku,
                    relationType,
                    validForSlug: slug,
                },
            };
        },
    },
});

export const {
    fallbackProductIdsReceived,
    loadingProductsFailed,
    loadingProductsStarted,
    productVariantSelected,
    productsReceived,
    slugProductLoading,
    slugProductNotFound,
    slugProductReceived,
    slugProductRemoved,
    variantProductRelationSelected,
} = productSlice.actions;

export default productSlice.reducer;
