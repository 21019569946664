import { Money } from './Money';
import { CategoryBase } from './ClientCategory';
import { ValueOf } from '../../helper/utility';

export interface GatewayProductCustomAsset {
    name: string;
    value: any;
}

export interface CustomAsset {
    customFieldsRaw: GatewayProductCustomAsset[];
}

export interface VariantImage {
    url: string;
    label: string;
}

export interface AssetSource {
    uri: string;
}

export const AssetTag = {
    DOWNLOAD: 'download',
    PRODUCT_IMAGE: 'product_image',
} as const;

export interface VariantAsset {
    custom: CustomAsset;
    usp?: string;
    locales?: string[];
    description: string;
    id: string;
    key: string;
    name: string;
    sources: AssetSource[];
    tags: [ValueOf<typeof AssetTag>];
    isGlobal?: boolean;
    isOnlyOneVariantActive?: boolean;
}

export type VariantAttributeValue = string | number | boolean | any;

export interface VariantDimensionValue {
    name: string;
    key: string;
    label: string;
    value: string;
    valueLabel: string;
}

export interface AttributeSet {
    badges?: {
        key: string;
        label: string;
    };
    baseUnitMultiplier?: number;
    brandName?: string;
    claim?: string;
    deliverables?: string[];
    deliveryTimeFrom?: string;
    deliveryTimeUntil?: string;
    isUpsell?: boolean;
    maxOrderQuantity?: number;
    name?: string;
    purchaseContent?: number;
    ratingCountLocalized?: number;
    ratingValueLocalized?: number;
    referenceUnit?: string;
    relatedCrosssellProduct: { id: string }[];
    relatedUpgrade: { id: string }[];
    relatedUpsell: { id: string }[];
    relatedUpsellBenelux: { id: string }[];
    relatedUpsellCee: { id: string }[];
    relatedUpsellGas: { id: string }[];
    setTypeLclzd?: string;
    shippingOptions?: string;
    shippingTime?: string;
    subscriptionBaseVariantSku?: string;
    subscriptionDefaultIntervall?: string;
    subscriptionIntervalls?: string[];
    subscriptionVariantSku?: string;
    usp?: string[];
    variantDimensions?: { key: string; label: string }[];
}

export interface Variant {
    assets: VariantAsset[];
    attributes: AttributeSet | Record<string, VariantAttributeValue>;
    cheapestCustomerPrice?: Money;
    cheapestPrice?: Money;
    country?: string;
    locale?: string;
    customerPrice?: Money;
    dimensions: VariantDimensionValue[];
    existsOtherThenCheapestCustomerPrices: boolean;
    existsOtherThenCheapestPrices: boolean;
    id: number;
    imageUri?: string;
    secondaryImageUri?: string;
    key: string;
    mightHaveCheaperSets: boolean;
    originalPrice?: Money;
    position: number;
    price: Money;
    sku: string;
    subscriptionPrice?: Money;
}

/**
 * Mapped commercetools product for usage by client.
 * You probably always work with {@see ProductBundle}, which is the merged version.
 */
export interface Product {
    categories: CategoryBase[];
    id: string;
    imageUri: string;
    secondaryImageUri: string;
    key: string;
    name: string;
    slug: string;
    variantDimensions: VariantDimensionValue[];
    variants: Variant[];
}

export type ProductWithActiveVariant = Product & { activeVariant?: Variant };

export interface ProductWithDeeplink extends Product {
    deepLink?: string;
}

export interface SearchLandingPage {
    name: string;
    url: string;
}
