import { lazy, ReactElement } from 'react';
import { BaseProps, BrxImageSetImpl } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const FlipLogoLazy = lazy(() => lazyRetry(() => import('./FlipLogo')));

export type FlipLogoProps = BaseProps & {
    exchangeLogoDesktop?: BrxImageSetImpl;
    exchangeLogoTablet?: BrxImageSetImpl;
    exchangeLogoMobile?: BrxImageSetImpl;
    logoDesktop: BrxImageSetImpl;
    logoTablet: BrxImageSetImpl;
    logoMobile: BrxImageSetImpl;
};

const FlipLogo = (props: FlipLogoProps): ReactElement => (
    <LazyComponent>
        <FlipLogoLazy {...props} />
    </LazyComponent>
);

export default FlipLogo;
