import { BaseProps } from '@mediashop/app/bloomreach/types';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useNavigate } from '@mediashop/app/hooks/useNavigate';
import { useOutsideClicked } from '@mediashop/app/hooks/useOutsideClicked';
import Icon from '@mediashop/base/pattern/atom/Icon';
import { LinkOpenInTab } from '@mediashop/base/pattern/atom/LinkOpenInTab';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getIsNavItemActive } from '../../helper';
import { MainNavigationCustomNavigationItemProps } from '../../types';
import DesktopSubNavigation from '../DesktopSubNavigation';

const componentName = 'desktop-navigation-item';

export type DesktopNavigationItemProps = BaseProps & {
    isLastItem: boolean;
    navItem: MainNavigationCustomNavigationItemProps;
};

const DesktopNavigationItem = ({ isLastItem, navItem }: DesktopNavigationItemProps) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [isItemClicked, setIsItemClicked] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLLIElement>(null);

    const hasChildItems = navItem.mainNavigationChildItems?.length;

    const openSubNavigation = () => {
        setIsOpen(true);
    };

    const resetNavItemStates = () => {
        setIsItemClicked(false);
        setIsOpen(false);
    };

    const onLinkClick = (event) => {
        // check if the device is a touch device
        if (matchMedia('(pointer:coarse)').matches && hasChildItems) {
            event.preventDefault();
            event.stopPropagation();

            if (isItemClicked) {
                resetNavItemStates();
                navigate(navItem.link.reference);
            } else {
                setIsItemClicked(true);
                setIsOpen(true);
            }
        }
    };

    useOutsideClicked(ref, () => resetNavItemStates());
    useEffect(() => resetNavItemStates(), [pathname]);

    return (
        <li
            ref={ref}
            className={classNames(componentName, {
                [`${componentName}--hover`]: isOpen,
            })}
            onMouseEnter={openSubNavigation}
            onTouchStart={openSubNavigation}
            onMouseLeave={resetNavItemStates}
        >
            <LinkOpenInTab
                className={`${componentName}__link`}
                tab={navItem.tab}
                link={navItem.link}
                onClick={onLinkClick}
                isActive={getIsNavItemActive(navItem.link.reference, pathname)}
            >
                <div className={`${componentName}__link-text`}>
                    {navItem.link.text}
                    {hasChildItems ? <Icon name="ArrowDown" className={`${componentName}__arrow`} /> : SKIP_RENDER}
                </div>
            </LinkOpenInTab>
            {hasChildItems && isOpen ? (
                <DesktopSubNavigation
                    isLastItem={isLastItem}
                    subNavigation={navItem.mainNavigationChildItems!}
                    closeSubNavigation={resetNavItemStates}
                />
            ) : (
                SKIP_RENDER
            )}
        </li>
    );
};

export default DesktopNavigationItem;
