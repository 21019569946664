import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useEffect, useRef, type KeyboardEvent } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import Icon from '@mediashop/base/pattern/atom/Icon';

const componentName = 'search-input';

type Props = {
    clearSearch?: () => void;
    debouncedSearchQuery: string;
    isMainNavigationCustom?: boolean;
    isMobileSearchPortal?: boolean;
    searchQuery: string;
    searchSubmit: (event?: KeyboardEvent<HTMLInputElement>) => void;
    setSearchQuery: (searchQuery: string) => void;
};

const SearchInput = ({
    clearSearch,
    debouncedSearchQuery,
    isMainNavigationCustom = false,
    isMobileSearchPortal = false,
    searchQuery,
    searchSubmit,
    setSearchQuery,
}: Props): JSX.Element => {
    const intl = useIntl();
    const searchInput = useRef<HTMLInputElement | null>(null);
    const autocompleteInput = useRef<HTMLInputElement | null>(null);

    const syncScrollPositionOfInputs = () => {
        if (searchInput.current && autocompleteInput.current) {
            autocompleteInput.current.scrollLeft = searchInput.current.scrollLeft;
        }
    };

    useEffect(() => {
        if (searchQuery.length > 0) {
            searchInput.current?.focus();
        }
    }, [searchQuery]);

    useEffect(() => {
        syncScrollPositionOfInputs();
    }, [debouncedSearchQuery]);

    const onClickSubmit = () => {
        if (searchQuery.length > 1) {
            searchSubmit();
        } else {
            searchInput.current?.focus();
        }
    };

    const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
        const inputValue = (event.target as HTMLInputElement).value;

        if (inputValue.length === 1) {
            setSearchQuery(inputValue.trimStart());
        } else {
            setSearchQuery(inputValue);
        }
    };

    return (
        <form
            className={classNames({
                [`${componentName}__form--open-portal`]: isMobileSearchPortal,
            })}
        >
            {/* Search */}
            <input
                ref={searchInput}
                className={classNames(`${componentName}__input`, {
                    [`${componentName}__input--open-portal`]: isMobileSearchPortal,
                })}
                value={searchQuery}
                onInput={handleInput}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === 'Enter') {
                        searchInput.current?.blur();
                    }
                    searchSubmit(event);
                }}
                type="text"
                id="main-navigation-search"
                placeholder={intl.formatMessage({ id: 'mainNavigation.search' })}
                name="search"
                onScroll={syncScrollPositionOfInputs}
            />

            {clearSearch && searchQuery.length ? (
                <div
                    onClick={() => clearSearch()}
                    className={classNames(`${componentName}__icon-button`, `${componentName}__icon-button-clear`, {
                        [`${componentName}__icon-button--open-portal`]: isMobileSearchPortal,
                    })}
                >
                    <Icon name="Close" />
                </div>
            ) : (
                SKIP_RENDER
            )}

            {/* Search button */}
            <div
                onClick={() => onClickSubmit()}
                className={classNames(`${componentName}__icon-button`, `${componentName}__icon-button-search`, {
                    [`${componentName}__icon-button--open-portal`]: isMobileSearchPortal,
                })}
            >
                {isMainNavigationCustom ? <Icon name="MagnifyingGlass" /> : <Icon name="Search" />}
            </div>
        </form>
    );
};

export default SearchInput;
