import { useMemo, useState, type ChangeEvent } from 'react';
import SelectButton from '@mediashop/base/pattern/molecule/SelectButton';
import Icon from '@mediashop/base/pattern/atom/Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import { injectComponent } from '@mediashop/app/component-injector';
import Quantity from '@mediashop/app/domain/Quantity';
import PreCheckoutFlyout from '../PreCheckoutFlyout';
import useRoute from '@mediashop/app/hooks/useRoute';
import { BaseProps, BrxRelationType } from '@mediashop/app/bloomreach/types';
import { Product, Variant } from '@mediashop/app/api/types/ClientProduct';
import { DetailedProduct } from '@mediashop/app/api/types/ClientProductDetailed';
import { useNavigate } from '@mediashop/app/hooks/useNavigate';

type BuyButtonProps = BaseProps & {
    onClick: (value: number) => void;
    minQuantity?: number;
    maxQuantity?: number;
    quantitySteps?: number;
    activeVariant?: Variant;
    isLoading: boolean;
    textId?: string;
    iconName?: string;
    goToCart?: boolean;
    product?: Product | DetailedProduct;
    showMobileIcon?: boolean;
    dealOfTheDay?: boolean;
    hidePreCheckoutFlyout?: boolean;
    productRelationType?: BrxRelationType;
    showSelect?: boolean;
    addToCartErrorMessage?: string;
    disabled?: boolean;
};

/**
 * Render a SelectButton with given quantity-options.
 */

function BuyButton({
    className = 'buy-btn',
    onClick,
    minQuantity = 1,
    maxQuantity = 10,
    quantitySteps = 1,
    isLoading,
    textId = 'product.buyNow',
    iconName = 'ArrowRightLight',
    goToCart = true,
    product,
    activeVariant,
    hidePreCheckoutFlyout = false,
    productRelationType,
    showSelect,
    addToCartErrorMessage,

    disabled = false,
}: BuyButtonProps) {
    const options = useMemo(
        () => Quantity.getSelectOptions(minQuantity, maxQuantity, quantitySteps),
        [minQuantity, maxQuantity, quantitySteps]
    );

    const intl = useIntl();
    const [value, setValue] = useState(options[0].value);
    const [showPreCheckout, setShowPreCheckout] = useState(false);
    const cartRoute = useRoute('Frontend.Master.Cart');
    const navigate = useNavigate();

    const handleButtonClick = () => {
        if (typeof onClick === 'function') {
            onClick(value);
        }

        if (goToCart) {
            navigate(cartRoute);
        } else {
            setShowPreCheckout(true);
        }
    };

    return (
        <>
            {showPreCheckout && product && activeVariant && !hidePreCheckoutFlyout ? (
                <PreCheckoutFlyout
                    activeVariant={activeVariant}
                    onClose={() => setShowPreCheckout(false)}
                    product={product}
                    productRelationType={productRelationType}
                    errorMessage={addToCartErrorMessage}
                />
            ) : null}
            <SelectButton
                className={className}
                onClick={handleButtonClick}
                options={options}
                value={value}
                isLoading={isLoading}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setValue(parseInt(event.target.value, 10))}
                ariaLabel={intl.formatMessage({ id: textId })}
                showSelect={showSelect}
                disabled={disabled}
            >
                <FormattedMessage id={textId} tagName="span" />
                {iconName && <Icon name={iconName} className="select-button__icon" />}
            </SelectButton>
        </>
    );
}

export default injectComponent('pattern.molecule.BuyButton', BuyButton, 'catalog-base');
