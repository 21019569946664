import { PayPalButtons, PayPalButtonsComponentProps } from '@paypal/react-paypal-js';
import LoadingIndicator from '@mediashop/base/pattern/atom/LoadingIndicator';
import { useInitiateExpressCheckout } from '@mediashop/app/queries/checkout/useInitiateExpressCheckout';
import { useSetAddressExpressCheckout } from '@mediashop/app/queries/checkout/useSetAddressExpressCheckout';
import { usePayPal } from '@mediashop/app/payment/payPal/usePayPal';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useCartContainsSubscription } from '@mediashop/app/hooks/useCartContainsSubscription';
import { useNavigate } from '@mediashop/app/hooks/useNavigate';
import { useExpressCheckoutConfig } from '@mediashop/app/queries/checkout/useExpressCheckoutConfig';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

const componentName = 'paypal-express';

const buttonStyle: PayPalButtonsComponentProps['style'] = {
    layout: 'vertical',
    shape: 'rect',
    color: 'white',
    height: 39,
    label: 'checkout',
};

interface PayPalExpressProps {
    disabled?: boolean;
}

export default function PayPalExpress({ disabled = false }: PayPalExpressProps) {
    const navigate = useNavigate();

    const { data = { payPal: { isEnabled: false } } } = useExpressCheckoutConfig();

    const isAllowedPaymentMethod = data.payPal.isEnabled;
    const cartContainsSubscription = useCartContainsSubscription();

    const { isLoading, isResolved, setIdToken } = usePayPal({ isEnabled: isAllowedPaymentMethod });

    useEffect(() => {
        if (data.payPal.idToken) {
            setIdToken(data.payPal.idToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.payPal.idToken]);

    const { mutateAsync: initExpressCheckout } = useInitiateExpressCheckout();
    const { mutateAsync: setAddress } = useSetAddressExpressCheckout();

    if (!isLoading && !isResolved) {
        return SKIP_RENDER;
    }

    if (!isAllowedPaymentMethod || cartContainsSubscription) {
        return SKIP_RENDER;
    }

    const handleCreateOrder = async () => {
        const { payPalOrderId } = await initExpressCheckout();
        return payPalOrderId;
    };

    const handleApprove = async () => {
        await setAddress();
        navigate('/checkout');
    };

    return !isResolved ? (
        <LoadingIndicator className={componentName} />
    ) : (
        <ErrorBoundary fallbackRender={() => null}>
            <div className={componentName}>
                <PayPalButtons
                    style={buttonStyle}
                    disabled={disabled}
                    fundingSource="paypal"
                    createOrder={handleCreateOrder}
                    onApprove={handleApprove}
                />
                <PayPalButtons
                    style={buttonStyle}
                    disabled={disabled}
                    fundingSource="paylater"
                    createOrder={handleCreateOrder}
                    onApprove={handleApprove}
                />
            </div>
        </ErrorBoundary>
    );
}
