import { BaseProps } from '@mediashop/app/bloomreach/types';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';

const componentName = 'product-hazard-warning';

type Props = BaseProps & {
    descriptionChemicalArticle: string;
    hazardWarnings: string[];
    hazardWarningsPictograms: string[];
    signalWord: string;
};

function HazardWarning({ descriptionChemicalArticle, hazardWarnings, hazardWarningsPictograms, signalWord }: Props) {
    return hazardWarnings?.length > 0 ? (
        <div className={componentName}>
            {descriptionChemicalArticle}
            <div className={`${componentName}__image-container`}>
                {hazardWarningsPictograms?.map((imgLink) => <img key={imgLink} alt="HazardWarning" src={imgLink} />)}
            </div>
            {signalWord}
            <ul className={`${componentName}__list`}>
                {hazardWarnings?.map((warning) => (
                    <li className={`${componentName}__list-item`} key={warning}>
                        {warning}
                    </li>
                ))}
            </ul>
        </div>
    ) : (
        SKIP_RENDER
    );
}

export default HazardWarning;
