import classNames from 'classnames';
import { useRef, useState } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import Icon from '../../atom/Icon';
import { FormattedMessage } from 'react-intl';
import { CountrySwitcherProps } from '@mediashop/base/types/country-switcher';
import { getCountryOptions, getLocaleOptions, getMappedCountry } from '@mediashop/base/utils/country-switcher';
import ShopSwitchModal from '../ShopSwitchModal';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useProject } from '@mediashop/app/hooks/useProject';
import { useShopContext } from '@mediashop/app/hooks/useShopContext';
import CountrySelectContainer from './CountrySelectContainer';
import * as icons from '@mediashop/base/icons';

const allIcons = icons.default;

const componentName = 'country-switch';

function CountrySwitch({ icon = 'ChevronDown', hasFlag = true, showTitle = true }: CountrySwitcherProps): JSX.Element {
    const { country: userCountry } = useShopContext();

    const { locales: configLocales, shippingCountries: configShippingCountries } = useProject();

    const selectedCountry = getMappedCountry(userCountry);

    const [isOpen, setIsOpen] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const refDropdown = useRef<HTMLDivElement>(null);

    const optionsCountries = getCountryOptions(configShippingCountries);
    const optionsLocales = getLocaleOptions(configLocales);

    const hideCountrySwitchIcon = optionsCountries.length === 1 && optionsLocales.length === 1;

    return (
        <div ref={wrapperRef} className={componentName}>
            <div className="dropdown" ref={refDropdown}>
                <div
                    data-testid="country-switch"
                    className={classNames('dropdown__header', {
                        'dropdown__header--clickable': !hideCountrySwitchIcon,
                    })}
                    onClick={!hideCountrySwitchIcon ? () => setIsOpen(!isOpen) : undefined}
                >
                    {showTitle ? (
                        <span className={`${componentName}__title`}>
                            <FormattedMessage id="countrySwitcher.headline" />
                        </span>
                    ) : (
                        SKIP_RENDER
                    )}

                    {hasFlag && (
                        <img
                            alt="Flag"
                            height="18"
                            width="25"
                            src={allIcons[selectedCountry.iconName]}
                            className={classNames(`dropdown__item-flag`, {
                                'dropdown__item-flag--select-disabled': hideCountrySwitchIcon,
                            })}
                        />
                    )}
                    {!hideCountrySwitchIcon ? (
                        <Icon
                            name={icon ?? 'ChevronDown'}
                            className={classNames({
                                'dropdown__country-switch-icon-filled': icon === 'ArrowDownFilled',
                            })}
                        />
                    ) : (
                        SKIP_RENDER
                    )}
                </div>
            </div>

            <CountrySelectContainer isOpen={isOpen} setIsOpen={setIsOpen} wrapperRef={wrapperRef} />

            <ShopSwitchModal />
        </div>
    );
}

export default injectComponent('pattern.atom.Countries', CountrySwitch);
